<template>
  <div class="compopts btm-2">
    <img
      src="@/assets/icons/company.svg"
      style="width: 80px; margin-right: 20px"
    />
    <label class="font-bold-24 namebreak">
      {{ companyData.companyName }}
    </label>
  </div>
  <LicenseAdminsList />
  <DashboardCards :showHeader="false" />
  <el-tabs v-model="activeTab" class="tabstyle mt-3" type="card">
    <el-tab-pane label="Licensed Products" name="products" disabled>
      <!-- <ProductsList /> -->
    </el-tab-pane>
    <el-tab-pane label="Subscriptions" name="subscriptions">
      <CompanySubscriptions />
    </el-tab-pane>
    <el-tab-pane label="Users" name="users">
      <CompanyUsers />
    </el-tab-pane>
  </el-tabs>
</template>
<style lang="scss">
.compopts {
  display: flex;
  flex-direction: row;
  background: #ffffff;
  width: 40vw;
  height: fit-content;
  padding: 2rem 50px;
  align-items: center;
}
.divstyl {
  align-items: center;
  display: flex;
  padding: 2rem 0;
  width: 100%;
}
.companytabstyle {
  border: 1px solid #e1e1e1;
  background: #d6d8da;
}
</style>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { CompanyInformation } from "@/utils/AppInterfaces";
import LicenseAdminsList from "./LicenseAdminsList.vue";
import DashboardCards from "./DashboardCards.vue";
import CompanyUsers from "./CompanyUsers.vue";
import CompanySubscriptions from "./CompanySubscriptions.vue";

export default defineComponent({
  name: "CompanyDetails",
  components: {
    LicenseAdminsList,
    DashboardCards,
    CompanyUsers,
    CompanySubscriptions,
  },
  computed: {
    ...mapGetters(["getCompanyData"]),
    companyData(): CompanyInformation {
      return this.getCompanyData;
    },
    companyId(): string {
      return this.getCompanyData ? this.getCompanyData.companyBucketId : "";
    },
  },
  data() {
    return {
      activeTab: "subscriptions",
      dashboardCardsReady: false,
    };
  },
  methods: {
    updateDashboardCardsStatus(status: boolean) {
      this.dashboardCardsReady = status;
    },
  },
});
</script>
