import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
//import SSOAuth from "@/utils/SSOAuth";
import Products from "../views/ProductsView.vue";
import Users from "../views/UsersView.vue";
import Companies from "../views/CompaniesView.vue";
import PageNotFound from "../views/PageNotFound.vue";
import { registerGuard } from "./Guard";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/auth/login-callback.html",
    name: "auth",
    component: HomeView,
  },
  {
    path: "/account",
    name: "account",
    component: HomeView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users",
    name: "user",
    component: Users,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/products",
    name: "product",
    component: Products,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/companies",
    name: "companies",
    component: Companies,
    meta: {
      requiresAuth: true,
    },
    // beforeEnter: checkUserRole
  },
  {
    path: "/:catchAll(.*)",
    name: "404",
    component: PageNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

registerGuard(router);

//SSOAuth.useRouter(router);

export default router;
