<template>
  <div class="usrprdtext btm-2">
    <h4 class="font-bold-24">Company Administrators</h4>
    <div class="utils">
      <el-button
        plain
        class="delbtn"
        @click="addCompanyAdmin"
        id="addadminbutton"
      >
        <img src="@/assets/icons/adduser.svg" style="width: 40px" />
      </el-button>
      <el-button
        plain
        class="delbtn"
        @click="showDeleteConfim = true"
        id="deleteadmin"
        :disabled="selectedUserIds.length === 0"
      >
        <img
          src="@/assets/icons/delete.svg"
          style="width: 40px"
          v-if="selectedUserIds.length !== 0"
        />
        <img
          src="@/assets/icons/deletedisabled.svg"
          style="width: 40px"
          v-if="selectedUserIds.length === 0"
        />
      </el-button>
      <el-dialog
        title="Delete Users"
        v-model="showDeleteConfim"
        width="35%"
        top="25vh"
        center
      >
        <el-divider></el-divider>
        <div class="text-div">
          <span
            ><strong>{{ selectedUserIds.length }}</strong> admin selected
          </span>
          <br />
          <span>Are you sure you want to delete company admin?</span>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button
              id="deleteadmincancel"
              @click="showDeleteConfim = false"
              round
              class="cancelbtn"
              variant="outline-dark"
              >Cancel</el-button
            >
            <el-button
              id="deleteadmincancel"
              type="danger"
              @click="removeCompanyAdmin"
              round
              class="deletebutton"
              >Confirm</el-button
            >
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
  <el-table
    id="licenseadmintable"
    v-loading="adminLoading"
    :data="licAdminsList"
    style="width: 100%"
    max-height="400"
    scrollbar-always-on
    @select="handleSelectionChange"
    @select-all="handleSelectionChange"
    :row-style="rowdatastyle"
    :show-header="false"
    empty-text="No license administrators"
  >
    <el-table-column type="selection" width="55" />
    <el-table-column prop="name">
      <template #default="name">
        <div>{{ name.row.firstName }} &nbsp;&nbsp; {{ name.row.lastName }}</div>
      </template>
    </el-table-column>
    <el-table-column prop="email"> </el-table-column>
    <el-table-column prop="status">
      <template #default="comp">
        <img
          src="@/assets/icons/active.svg"
          style="width: 25px"
          v-if="comp.row.status === 'Active'"
        />
        <img
          src="@/assets/icons/inactive.svg"
          style="width: 25px"
          v-if="comp.row.status === 'inactive'"
        />
        <img
          src="@/assets/icons/pending.svg"
          style="width: 25px"
          v-if="comp.row.status === 'pending'"
        />
        &nbsp;&nbsp;{{ comp.row.status }}
      </template>
    </el-table-column>
  </el-table>
  <el-drawer
    v-model="showAddCompanyAdmin"
    direction="rtl"
    destroy-on-close
    size="40%"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :wrapperClosable="false"
  >
    <AddCompanyAdminModal />
  </el-drawer>
</template>
<style lang="scss"></style>
<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import APIService from "@/utils/APIService";
import { CompanyActionTypes } from "@/store/companystore/CompanyActionTypes";
import { FlowActionTypes } from "@/store/flowstore/FlowActionTypes";
import { UserActionTypes } from "@/store/userstore/UserActionTypes";
import AddCompanyAdminModal from "@/components/AddCompanyAdminModal.vue";
import { ElMessage } from "element-plus";
import { UserInformation } from "@/utils/AppInterfaces";

export default defineComponent({
  name: "LicenseAdminsList",
  components: {
    AddCompanyAdminModal,
  },
  computed: {
    ...mapGetters([
      "getCompanyData",
      "getLicAdmins",
      "getShowAddCompanyAdmin",
      "getSelectedUserids",
    ]),
    licAdminsList(): any {
      return this.getLicAdmins.items;
    },
    showAddCompanyAdmin: {
      get(): boolean {
        return this.getShowAddCompanyAdmin;
      },
      set() {
        this.$store.dispatch(UserActionTypes.SET_USERS_WITHOUTCOMPANYADMIN, []);
        this.$store.dispatch(
          CompanyActionTypes.SET_SHOW_ADDCOMPANYADMIN,
          false
        );
      },
    },
    selectedUserIds(): any {
      return this.getSelectedUserids || [];
    },
  },
  data() {
    return {
      rowdatastyle: {
        "font-family": "Open Sans, sans-serif",
        "font-weight": "400",
        "font-size": "14px",
        "line-height": "24px",
        color: "black",
      },
      adminData: {},
      adminLoading: true,
      showDeleteConfim: false,
    };
  },
  methods: {
    init() {
      const apiService = new APIService(this.axios);
      apiService
        .getCompLicAdmins(this.getCompanyData.companyBucketId)
        .then((res) => {
          this.$store.dispatch(CompanyActionTypes.SET_AVAILABLE_LICADMINS, res);
          this.adminLoading = false;
        });
    },
    handleSelectionChange(selected: any) {
      const userBucketIds: any[] = [];
      selected.map((user: UserInformation) =>
        userBucketIds.push(user.userBucketId)
      );
      this.$store.dispatch(FlowActionTypes.SET_SELECTED_USERIDS, userBucketIds);
    },
    addCompanyAdmin() {
      this.$store.dispatch(CompanyActionTypes.SET_SHOW_ADDCOMPANYADMIN, true);
    },
    removeCompanyAdmin() {
      const userData = {
        roleId: 4,
        userBucketIdCollection: this.selectedUserIds,
        companyBucketId: this.getCompanyData.companyBucketId,
      };
      const apiService = new APIService(this.axios);
      apiService.removeCompanyAdmin(userData).then((res) => {
        if (res.success) {
          this.showDeleteConfim = false;
          this.$store.dispatch(FlowActionTypes.SET_SELECTED_USERIDS, []);
          apiService
            .getCompLicAdmins(this.getCompanyData.companyBucketId)
            .then((resp) => {
              this.$store.dispatch(
                CompanyActionTypes.SET_AVAILABLE_LICADMINS,
                resp
              );
              this.adminLoading = false;
              this.showDeleteConfim = false;
            });
          ElMessage({
            message: "Company admin has been removed successfully.",
            type: "success",
          });
        } else {
          ElMessage.error(res.validationResult.validationMessages[0]);
        }
      });
    },
  },
  mounted() {
    this.init();
  },
});
</script>
