<template>
  <div typeof="primary" v-loading.fullscreen.lock="currentUserEmpty">
    <div v-if="!currentUserEmpty" v-loading="!contentReady">
      <DashboardCards
        showHeader
        @dashboardCardsLoaded="updateDashboardCardsStatus"
      />
      <SubscriptionsList @subsListLoaded="updatesubscriptionsListStatus" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DashboardCards from "@/components/DashboardCards.vue";
import SubscriptionsList from "@/components/SubscriptionsList.vue";
import { mapGetters } from "vuex";
import _ from "lodash";

export default defineComponent({
  name: "HomeView",
  components: {
    DashboardCards,
    SubscriptionsList,
  },
  computed: {
    ...mapGetters(["getCurrentUser"]),
    currentUserEmpty(): boolean {
      return _.isEmpty(this.getCurrentUser);
    },
    contentReady(): boolean {
      return (
        !this.currentUserEmpty &&
        this.dashboardCardsReady &&
        this.subscriptionsListReady
      );
    },
  },
  data() {
    return {
      dashboardCardsReady: false,
      subscriptionsListReady: false,
    };
  },
  methods: {
    updateDashboardCardsStatus(status: boolean) {
      this.dashboardCardsReady = status;
    },
    updatesubscriptionsListStatus(status: boolean) {
      this.subscriptionsListReady = status;
    },
  },
});
</script>
