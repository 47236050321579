<template>
  <el-table
    id="productslisttable"
    :data="productsList"
    style="width: 100%"
    :header-cell-style="headerstyle"
    :row-style="rowdatastyle"
    empty-text="No products data available"
  >
    <el-table-column label="Product Name" prop="productName"> </el-table-column>
    <el-table-column label="Version">
      <template #default="props">
        {{ props.row.majorVersionNumber }}.{{ props.row.minorVersionNumber }}
      </template>
    </el-table-column>
    <el-table-column label="License Type" prop="licenseType"> </el-table-column>
    <el-table-column label="License Used">
      <template #default="props">
        {{ props.row.seatsUsed }}/ {{ props.row.seats }}
      </template>
    </el-table-column>
    <el-table-column fixed="right" label="" width="120">
      <template #default="product">
        <el-button
          @click="viewDetails(product)"
          size="small"
          id="viewdetails"
          link
        >
          <img src="@/assets/icons/openlistdetails.svg" style="width: 25px" />
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<style lang="scss">
.content {
  flex-direction: column;
  margin: 0;
  padding: 1% 2vw 1% 0;
}
.prdtext {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}
.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #f2af00;
  border-color: #f2af00;
}

.el-table td {
  border-bottom: 3px solid #f2f2f2;
}

.el-checkbox__inner {
  border: 2px solid #666666;
}
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #f2af00;
  border-color: #f2af00;
}
</style>

<script lang="ts">
import { defineComponent } from "vue";
import APIService from "@/utils/APIService";
import { mapGetters } from "vuex";
// import _ from "lodash";
import { ProductActionTypes } from "@/store/productstore/ProductActionTypes";
import { FlowActionTypes } from "@/store/flowstore/FlowActionTypes";

export default defineComponent({
  name: "ProductsList",
  computed: {
    ...mapGetters(["getProductsList"]),
    productsList(): any {
      return this.getProductsList.items;
    },
  },
  data() {
    return {
      newprodList: [],
      headerstyle: {
        "border-bottom": "unset",
        background: "#F2F2F2",
        "font-family": "Open Sans, sans-serif",
        "font-weight": "700",
        "font-size": "0.8rem",
        "line-height": "1rem",
        color: "black",
      },
      rowdatastyle: {
        "font-family": "Open Sans, sans-serif",
        "font-weight": "400",
        "font-size": "0.8rem",
        "line-height": "1rem",
        color: "black",
      },
    };
  },
  methods: {
    getProducts() {
      const apiService = new APIService(this.axios);
      apiService.getProductList().then((res) => {
        this.$store.dispatch(ProductActionTypes.SET_PRODUCTS, res);
      });
    },
    viewDetails(product: any) {
      this.$store.dispatch(FlowActionTypes.SET_SUBSCRIPTION_DATA, {});
      this.$store.dispatch(FlowActionTypes.SET_PRODUCT_DATA, product.row);
    },
  },
  mounted() {
    this.getProducts();
  },
});
</script>
