export enum CompanyMutationTypes {
  UPDATE_COMPANIES = "UPDATE_COMPANIES",
  UPDATE_ALL_COMPANIES = "UPDATE_ALL_COMPANIES",
  UPDATE_COMPANY_DATA = "UPDATE_COMPANY_DATA",
  UPDATE_AVAILABLE_LICADMINS = "UPDATE_AVAILABLE_LICADMINS",
  UPDATE_LIC_USAGE = "UPDATE_LIC_USAGE",
  UPDATE_COMPANY_USERCOUNT = "UPDATE_COMPANY_USERCOUNT",
  UPDATE_SHOW_ADDCOMPANYADMIN = "UPDATE_SHOW_ADDCOMPANYADMIN",
  UPDATE_COMPANY_SUBSCRIPTIONS = "UPDATE_COMPANY_SUBSCRIPTIONS",
}
