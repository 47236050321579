export enum FlowMutationTypes {
  UPDATE_SHOW_DETAILS = "UPDATE_SHOW_DETAILS",
  UPDATE_SHOW_ADDUSER = "UPDATE_SHOW_ADDUSER",
  UPDATE_SELECTED_EMAILS = "UPDATE_SELECTED_EMAILS",
  UPDATE_SELECTED_PRODUCTS = "UPDATE_SELECTED_PRODUCTS",
  UPDATE_PRODUCT_DATA = "UPDATE_PRODUCT_DATA",
  UPDATE_USER_DATA = "UPDATE_USER_DATA",
  UPDATE_SHOW_ADDPRODUCTS = "UPDATE_SHOW_ADDPRODUCTS",
  UPDATE_SUBSCRIPTION_DATA = "UPDATE_SUBSCRIPTION_DATA",
  UPDATE_TASK_TYPE = "UPDATE_TASK_TYPE",
  UPDATE_SELECTED_USERS = "UPDATE_SELECTED_USERS",
  UPDATE_SELECTED_USERIDS = "UPDATE_SELECTED_USERIDS",
  UPDATE_CURRENT_PAGE = "UPDATE_CURRENT_PAGE",
  UPDATE_SEARCH_VALUE = "UPDATE_SEARCH_VALUE",
  UPDATE_SHOWHEADER_SIDEBAR = "UPDATE_SHOWHEADER_SIDEBAR",
  UPDATE_ADDON_DATA = "UPDATE_ADDON_DATA",
  UPDATE_ADDON_USERS = "UPDATE_ADDON_USERS",
  UPDATE_ADDON_PRODUCTS = "UPDATE_ADDON_PRODUCTS",
  UPDATE_SHOW_ASSIGNADDON = "UPDATE_SHOW_ASSIGNADDON",
  UPDATE_SHOW_REMOVEADDON = "UPDATE_SHOW_REMOVEADDON",
}
