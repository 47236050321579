<template>
  <el-menu
    class="el-menu-demo"
    mode="horizontal"
    :ellipsis="false"
    menu-trigger="click"
    :default-active="activeIndex"
    @select="handleSelect"
  >
    <el-menu-item index="/">
      <img src="@/assets/logo.svg" class="imgstyle" />
    </el-menu-item>
    <h2 class="font-light">Accounts</h2>
    <div
      class="headermenudiv hidemenu"
      v-if="userRole !== 'User' && userAuthenitcated"
    >
      <el-menu-item index="/account" class="menulabel" @click="tabClicked">
        Overview
      </el-menu-item>
      <el-menu-item index="/products" class="menulabel" @click="tabClicked">
        Products &#38; Services
      </el-menu-item>
      <el-menu-item index="/users" class="menulabel" @click="tabClicked">
        Users
      </el-menu-item>
      <el-menu-item
        index="/companies"
        class="menulabel"
        @click="tabClicked"
        v-if="userRole !== 'LicenseAdmin'"
      >
        Companies
      </el-menu-item>
    </div>
    <div class="flex-grow"></div>
    <div class="complbldiv">
      <label
        class="complabel hidemenu"
        v-if="userRole !== 'User'"
        style="font-weight: 700; margin-bottom: 0"
      >
        {{ currentUserCompany.companyName }}</label
      >
    </div>
    <el-sub-menu index="4" class="divborder hidemenu">
      <template #title>
        <el-button circle class="namebtn" type="warning" id="nameinitials">
          {{ nameInitials }}
        </el-button>
      </template>
      <el-menu-item-group>
        <div class="dropdowndiv">
          <label class="complabel" style="margin-bottom: 0">
            {{ userName }}
            <!-- {{ currentUserCompany.firstName }}
        {{ currentUserCompany.lastName }} -->
          </label>
        </div>
      </el-menu-item-group>
      <el-divider />
      <el-menu-item index="2-1" class="menu-font" @click="onmyaccount">
        My Profile
      </el-menu-item>
      <el-divider />
      <el-menu-item index="2-1" class="menu-font" id="logout" @click="onlogout">
        Logout
      </el-menu-item>
      <el-divider />
      <el-menu-item-group style="background: #f2f2f2">
        <UsefullLinks />
      </el-menu-item-group>
    </el-sub-menu>
    <div class="showham divmargin">
      <el-button
        @click="showHeaderSideBar"
        link
        size="large"
        id="showsideheader"
      >
        <img src="@/assets/icons/menu.svg" style="width: 25px" />
      </el-button>
    </div>
  </el-menu>
  <el-drawer
    v-model="ShowSideHeader"
    direction="rtl"
    size="30%"
    class="showham"
  >
    <HeaderSideBar />
  </el-drawer>
</template>

<style scoped lang="scss">
@media screen and (max-width: 768px) {
  h2 {
    display: none;
  }
  div.complbldiv {
    border-right: unset;
    padding: unset;
  }
}

@media screen and (max-width: 1280px) {
  div.hidemenu,
  .hidemenu {
    display: none;
  }
  .namebtn {
    margin: 0.5rem 1rem !important;
    pointer-events: none;
  }
  .complabel {
    margin: 0.5rem 1rem !important;
    color: #333333;
  }
}

@media screen and (min-width: 1280px) {
  div.showham {
    display: none;
  }
}

.divmargin {
  margin: 15px;
}
.dropdowndiv {
  display: flex;
  // justify-content: center;
  width: 13rem;
  margin: 10px 0;
}

.el-menu-item-group {
  display: flex;
}

.el-divider--horizontal {
  margin-bottom: unset !important;
  margin-top: 10px !important;
}

.el-menu--popup {
  padding: unset !important;
}

.el-icon--right {
  margin: unset !important;
}

.dotslablestyle {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}

.el-button.is-circle {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 27px;
  color: #000000;
}
.menulabel {
  color: #333333;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  min-width: 12rem;
  justify-content: center;
  align-items: center;
}

.headermenudiv {
  display: flex;
  margin-right: auto;
  margin-left: auto;
}

.namebtn {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 27px;
  color: #000000;
  background: #f2af00;
  border: 2px solid #f2af00;
  min-height: 2.5rem;
}

.font-light {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  margin: auto 12px;
}

.divborder {
  border-left: 2px solid rgb(214, 216, 218);
}

.complbldiv {
  display: flex;
  flex-direction: row;
  padding-right: 20px;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}
.menu-font {
  color: #333333;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin-left: 15px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  width: 250px;
}

.complabel {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  word-break: break-word;
  overflow-wrap: break-word;
}
.imgstyle {
  height: 2.5rem;
  border-right: 2px solid #d6d8da;
  padding-right: 20px;
  margin-top: 0.5rem;
}
</style>

<script lang="ts">
import { defineComponent } from "vue";
import { RouteLocationRaw } from "vue-router";
import { mapGetters } from "vuex";
import APIService from "@/utils/APIService";
//import SSOAuth from "@/utils/SSOAuth";
import _ from "lodash";
import { FlowActionTypes } from "@/store/flowstore/FlowActionTypes";
import { UserActionTypes } from "@/store/userstore/UserActionTypes";
import { AuthActionTypes } from "@/store/authstore/AuthActionTypes";
import { CompanyActionTypes } from "@/store/companystore/CompanyActionTypes";
import HeaderSideBar from "./HeaderSideBar.vue";
import UsefullLinks from "./UsefullLinks.vue";
import { useIsAuthenticated } from "../composition-api/useIsAuthenticated";
import { msalInstance } from "@/authConfig";

export default defineComponent({
  name: "HeaderComponent",
  props: {
    msg: String,
  },
  components: {
    HeaderSideBar,
    UsefullLinks,
  },
  data() {
    return {
      nameInitials: "",
      userName: "",
      userRole: "",
      fetchingCurrentUser: false,
    };
  },
  computed: {
    ...mapGetters(["getCurrentUser", "getShowSideHeader"]),
    currentUserCompany(): any {
      this.updateUserData();
      if (this.nameInitials === "") this.setInitials();
      return _.isEmpty(this.getCurrentUser) ? "" : this.getCurrentUser.result;
    },
    ShowSideHeader: {
      get(): boolean {
        return this.getShowSideHeader;
      },
      set() {
        this.$store.dispatch(FlowActionTypes.SET_SHOWHEADER_SIDEBAR, false);
      },
    },
    activeIndex(): any {
      return this.$route.path;
    },
    userAuthenitcated(): boolean {
      if (this.$route.name === undefined && !useIsAuthenticated().value)
        window.history.go(0);
      return useIsAuthenticated().value;
    },
  },
  methods: {
    async onlogout() {
      await msalInstance.logoutRedirect();
    },
    processLogout() {
      this.$store.dispatch(UserActionTypes.SET_USER_ERROR, false);
      this.$store.dispatch(AuthActionTypes.LOGOUT);
    },
    updateUserData() {
      if (
        useIsAuthenticated().value &&
        _.isEmpty(this.getCurrentUser) &&
        !this.fetchingCurrentUser
      ) {
        this.fetchingCurrentUser = true;
        const apiService = new APIService(this.axios);
        apiService
          .getCurrentUser()
          .then((res: any) => {
            if (!_.isEmpty(res) && res.result.currentRole !== "User") {
              this.$store.dispatch(UserActionTypes.SET_CURRENTUSER, res);
              this.userName = `${res.result.firstName} ${res.result.lastName}`;
              this.userRole = res.result.currentRole;
              this.fetchingCurrentUser = false;
            } else {
              this.fetchingCurrentUser = false;
              // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
              const myproduct = process.env.VUE_APP_MY_PRODUCTS!;
              // const muproducturl = `${myproduct}?r=${window.location}`;
              window.location.replace(myproduct);
            }
          })
          .catch((err) => {
            if (err.code.includes("ERR_BAD")) this.onlogout();
            else this.$store.dispatch(UserActionTypes.SET_USER_ERROR, true);
          });
      }
    },
    setInitials() {
      this.nameInitials =
        !_.isEmpty(this.getCurrentUser) &&
        this.getCurrentUser.result.firstName &&
        this.getCurrentUser.result.lastName
          ? this.getCurrentUser.result.firstName.substring(0, 1).toUpperCase() +
            this.getCurrentUser.result.lastName.substring(0, 1).toUpperCase()
          : "";
    },
    showHeaderSideBar() {
      this.$store.dispatch(FlowActionTypes.SET_SHOWHEADER_SIDEBAR, true);
    },
    tabClicked() {
      this.$store.dispatch(FlowActionTypes.SET_USER_DATA, {});
      this.$store.dispatch(FlowActionTypes.SET_SUBSCRIPTION_DATA, {});
      this.$store.dispatch(FlowActionTypes.SET_PRODUCT_DATA, {});
      this.$store.dispatch(CompanyActionTypes.SET_COMPANY_DATA, {});
      this.$store.dispatch(CompanyActionTypes.SET_LIC_USAGE, {});
      this.$store.dispatch(CompanyActionTypes.SET_COMPANY_USERCOUNT, {});
      this.$store.dispatch(FlowActionTypes.SET_SEARCH_VALUE, "");
      this.$store.dispatch(UserActionTypes.SET_ASSIGNED_PRODUCTS, {});
      this.$store.dispatch(CompanyActionTypes.SET_COMPANIES, {});
    },
    onmyaccount() {
      const myaccount = process.env.VUE_APP_MY_ACCOUNT_SERVER || "";
      // const myaccountParams = `${myaccount}?r=${window.location}`;
      window.location.href = myaccount;
    },
    handleSelect(key: RouteLocationRaw) {
      if (key === "/") this.$router.go(0);
      if (key !== "2-1") this.$router.push(key);
    },
  },
});
</script>
