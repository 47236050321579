import {
  MutationTree,
  ActionTree,
  ActionContext,
  GetterTree,
  Store as VuexStore,
  CommitOptions,
  DispatchOptions,
  Module,
} from "vuex";
import { AuthMutationTypes } from "./AuthMutationTypes";
import { AuthActionTypes } from "./AuthActionTypes";
import { State, state } from "../AppState";
// import { useMsal } from "@/composition-api/useMsal";
// import SSOAuth from "@/utils/SSOAuth";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, State>, "commit">;

export interface Actions {
  [AuthActionTypes.LOGIN](
    context: AugmentedActionContext,
    userdata: any
  ): Promise<boolean>;
  [AuthActionTypes.SET_ERROR](
    context: AugmentedActionContext,
    error: any
  ): Promise<boolean>;
  [AuthActionTypes.LOGOUT](context: AugmentedActionContext): Promise<boolean>;
}

export const actions: ActionTree<State, State> = {
  [AuthActionTypes.LOGIN](context: AugmentedActionContext, userdata: any) {
    return context.commit(AuthMutationTypes.SET_AUTH, userdata);
  },
  [AuthActionTypes.SET_ERROR](context: AugmentedActionContext, error: boolean) {
    return context.commit(AuthMutationTypes.UPDATE_ERROR, error);
  },
  async [AuthActionTypes.LOGOUT](context: AugmentedActionContext, user: any) {
    return context.commit(AuthMutationTypes.DELETE_AUTH, user);
    // return SSOAuth.signOut();
  },
};

export type Mutations<S = State> = {
  [AuthMutationTypes.SET_AUTH](state: S, user: any): void;
  [AuthMutationTypes.UPDATE_ERROR](state: S, error: boolean): void;
  [AuthMutationTypes.DELETE_AUTH](state: S): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [AuthMutationTypes.SET_AUTH](state, data: any) {
    state.user = data;
  },
  [AuthMutationTypes.UPDATE_ERROR](state, error: boolean) {
    state.errorOccured = error;
  },
  [AuthMutationTypes.DELETE_AUTH](state) {
    state.user = {};
  },
};
export type Getters = {
  currentUser(state: State): any;
  getErrorOccured(state: State): boolean;
};

export const getters: GetterTree<State, State> = {
  currentUser: (state) => state.user,
  getErrorOccured: (state) => state.errorOccured,
};

export type AuthStore<S = State> = Omit<
  VuexStore<S>,
  "getters" | "commit" | "dispatch"
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>;
  };
};

export const authstore: Module<State, State> = {
  state,
  getters,
  mutations,
  actions,
};
