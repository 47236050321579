export enum CompanyActionTypes {
  SET_COMPANIES = "SET_COMPANIES",
  SET_ALL_COMPANIES = "SET_ALL_COMPANIES",
  SET_COMPANY_DATA = "SET_COMPANY_DATA",
  SET_AVAILABLE_LICADMINS = "SET_AVAILABLE_LICADMINS",
  SET_LIC_USAGE = "SET_LIC_USAGE",
  SET_COMPANY_USERCOUNT = "SET_COMPANY_USERCOUNT",
  SET_SHOW_ADDCOMPANYADMIN = "SET_SHOW_ADDCOMPANYADMIN",
  SET_COMPANY_SUBSCRIPTIONS = "SET_COMPANY_SUBSCRIPTIONS",
}
