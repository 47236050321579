<template>
  <div class="userviewopts mr-5">
    <div class="divwithdelete">
      <div class="deletediv">
        <el-button
          plain
          class="delbtn"
          @click="showDeleteDailogue = true"
          id="deleteuser"
        >
          <img src="@/assets/icons/delete.svg" style="width: 40px" />
        </el-button>
      </div>
      <div class="userdivstyl">
        <img
          src="@/assets/icons/user.svg"
          style="width: 80px; margin-right: 20px"
        />
        <div>
          <h2 class="font-bold-24 usernamebrk">
            {{ userData.firstName }}
            {{ userData.lastName }}
          </h2>
          <label class="font-regular-18">
            {{ userData.companyName }}
          </label>
          <div style="display: flex; flex-direction: column">
            <label class="font-regular-18">
              {{ userData.email }}
            </label>
            <label class="font-regular-18">
              Status: {{ userData.userStatus }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <el-dialog
    title="Delete Users"
    v-model="showDeleteDailogue"
    width="35%"
    top="25vh"
    center
  >
    <el-divider></el-divider>
    <div class="text-div font-regular-16">
      <span
        >Selected user
        <strong> {{ userData.firstName }} {{ userData.lastName }}</strong>
      </span>
      <br />
      <span class="font-regular-16"
        >All user data and subscriptions will be permanently removed.</span
      >
      <span>This action may not be undone.</span>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          @click="showDeleteDailogue = false"
          round
          class="cancelbtn"
          variant="outline-dark"
          >Cancel</el-button
        >
        <el-button type="danger" @click="deleteUser" round class="deletebutton"
          >Confirm</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<style lang="scss"></style>
<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import APIService from "@/utils/APIService";
import { FlowActionTypes } from "@/store/flowstore/FlowActionTypes";
import { ElMessage } from "element-plus";
import _ from "lodash";

export default defineComponent({
  ame: "UserSubDetails",
  components: {},
  computed: {
    ...mapGetters(["getUserData"]),
    userData(): any {
      return !_.isEmpty(this.getUserData) ? this.getUserData : "";
    },
  },
  data() {
    return {
      showDeleteDailogue: false,
    };
  },
  methods: {
    deleteUser() {
      const apiService = new APIService(this.axios);
      const emailData = {
        UserBucketIdCollection: [this.getUserData.userBucketId],
      };
      apiService.deleteUsers(emailData).then((res) => {
        if (res.success) {
          this.$store.dispatch(FlowActionTypes.SET_USER_DATA, {});
          this.showDeleteDailogue = false;
          ElMessage({
            message: "User has been deleted successfully.",
            type: "success",
          });
        } else {
          ElMessage.error(res.validationResult.validationMessages[0]);
        }
      });
    },
  },
});
</script>
