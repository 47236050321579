<template>
  <h4 class="modal-title" id="assignadmintitle">ASSIGN COMPANY ADMIN</h4>
  <div class="modalbody">
    <div v-if="displayOption === 'radioSelection'" class="radioflowdiv">
      <el-radio
        id="selectexistinguser"
        v-model="selectAssignOption"
        label="existingUser"
        @change="selectionChanged"
      >
        Assign admin from existing users</el-radio
      >
      <div class="mb-3">
        <el-button
          round
          id="assignadmin"
          class="btnstyle btnsize ml-4"
          variant="warning"
          @click="assignExisting"
          :disabled="selectAssignOption === 'newUser'"
        >
          Assign Admin
        </el-button>
      </div>
      <el-radio
        id="addnewadmin"
        v-model="selectAssignOption"
        label="newUser"
        @change="selectionChanged"
      >
        Assign admin from new user</el-radio
      >
      <div
        :class="
          showLightFont ? 'radiodiv lblfontlight' : 'radiodiv lblfontdark'
        "
      >
        <label class="divlblfont mb-3">
          Admin rights will be assigned to new user after users's account is
          activated</label
        >
        <el-button
          round
          id="newadmin"
          class="btnstyle ml-4"
          variant="warning"
          @click="assignNewUser"
          :disabled="selectAssignOption === 'existingUser'"
        >
          Invite new user
        </el-button>
      </div>
    </div>
    <div v-if="displayOption === 'existingUsers'" style="height: 90%">
      <label class="bottommargin titlestyle">Select existing users</label>
      <el-input
        id="companyusersearch"
        v-model="userSearchValue"
        placeholder="Search"
        type="search"
        debounce="1000"
        class="rightmargin inpstyle bottommargin"
      >
        <template #suffix>
          <img src="@/assets/icons/search.svg" style="width: 25px" />
        </template>
      </el-input>
      <div class="overflowdiv">
        <el-table
          id="companyusertable"
          v-loading="loading"
          :data="existingUsers"
          border
          style="width: 100%"
          :show-header="false"
          :row-style="rowdatastyle"
          @select="handleSelectionChange"
          @select-all="handleSelectionChange"
          :default-sort="{ prop: 'email', order: 'ascending' }"
          empty-text="Users not Available"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="User Name" prop="email" sortable>
            <template #default="props">
              <div>
                <label class="tblabelfont"
                  >{{ props.row.firstName }} {{ props.row.lastName }}</label
                >
                &#40;{{ props.row.email }} &#41;
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div v-if="displayOption === 'newUser'">
      <label class="bottommargin titlestyle">Invite new user</label>
      <el-form
        label-width="auto"
        label-position="top"
        size="default"
        class="topmargin"
      >
        <el-form-item label="Company" class="firstLetterCapital">
          <el-select
            id="selectedadmincompany"
            v-model="selectedCompany"
            class="user-inputstyle"
            size="large"
            disabled
          >
            <el-option
              v-for="(item, index) in companyData"
              :key="index"
              :label="item.companyName"
              :value="item.companyValue"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="First Name" class="firstLetterCapital">
          <el-input
            id="adminfirstname"
            required
            v-model="inviteData.firstName"
            placeholder="First Name"
            :maxLength="maxNameLength"
            class="user-inputstyle"
          />
          <span class="fieldLength"
            >{{ maxNameLength - inviteData.firstName.length }} /
            {{ maxNameLength }}
          </span>
        </el-form-item>
        <el-form-item label="Last Name" class="firstLetterCapital">
          <el-input
            id="adminlastname"
            required
            v-model="inviteData.lastName"
            placeholder="Last Name"
            :maxLength="maxNameLength"
            class="user-inputstyle"
          />
          <span class="fieldLength"
            >{{ maxNameLength - inviteData.lastName.length }} /
            {{ maxNameLength }}
          </span>
        </el-form-item>
        <el-form-item label="Email address" class="firstLetterCapital">
          <el-input
            id="adminemail"
            required
            v-model="inviteData.email"
            placeholder="Enter Email"
            :maxLength="maxEmailLength"
            class="user-inputstyle"
          />
          <span class="fieldLength"
            >{{ maxEmailLength - inviteData.email.length }} /
            {{ maxEmailLength }}
          </span>
        </el-form-item>
      </el-form>
      <div v-if="noemail" class="errordiv emailerror">
        Please enter the valid email address
      </div>
      <div v-if="hasError" class="errordiv">All the fields are required!</div>
    </div>
    <div v-if="showError && addUserError !== ''" class="maxitemsdiv errordiv">
      <img src="@/assets/icons/warnerror.svg" class="warnimagestyle" />
      <label style="padding: 1rem 0">{{ addUserError }}</label>
    </div>
    <div v-if="displayOption === 'emailSent'">
      <div class="labeldiv">
        <img
          src="@/assets/icons/mailsent.svg"
          style="width: 70px; margin-bottom: 1rem"
        />
        <label class="labelbld"
          >Email invitation and admin notification sent to:
        </label>

        <div
          v-if="inviteData.email.length === 0"
          style="display: flex; flex-direction: column"
        >
          <p
            class="labelfont"
            v-for="(item, index) in selectedUsers"
            :key="index"
          >
            <strong>{{ item.firstName }}&nbsp;{{ item.lastName }}</strong>
            <br />
            &#40;{{ item.email }} &#41;
          </p>
        </div>
        <div style="display: flex" v-if="inviteData.email.length !== 0">
          <p class="labelfont">
            <strong
              >{{ inviteData.firstName }}&nbsp;{{ inviteData.lastName }}</strong
            >
            <br />
            &#40;{{ inviteData.email }} &#41;
          </p>
        </div>
      </div>
    </div>
    <div class="buttonsdiv">
      <el-button
        id="adminexistingnext"
        round
        class="btnstyle ml-4"
        variant="warning"
        @click="nextClickHandler"
        v-if="
          displayOption !== 'emailSent' && displayOption === 'existingUsers'
        "
        :disabled="!enableControls || selectedUsers.length === 0"
        :loading="!enableControls && !showError"
      >
        Next
      </el-button>
      <el-button
        id="adminnewnext"
        round
        class="btnstyle ml-4"
        variant="warning"
        @click="nextClickHandler"
        v-if="displayOption !== 'emailSent' && displayOption === 'newUser'"
        :disabled="!enableControls && inviteData.firstName !== ''"
        :loading="!enableControls && !showError"
      >
        Next
      </el-button>
      <el-button
        id="admincancel"
        round
        variant="outline-dark"
        @click="closeModal"
        class="cancelbtn"
        v-if="displayOption !== 'emailSent'"
      >
        Cancel
      </el-button>
      <el-button
        id="admindone"
        round
        class="btnstyle ml-4"
        variant="warning"
        @click="closeModal"
        v-if="displayOption === 'emailSent'"
      >
        Done
      </el-button>
    </div>
  </div>
</template>

<style lang="scss">
.margintop {
  margin-top: 5rem;
}
.el-table__fixed-right-patch {
  background-color: #f2f2f2;
}
.btnsize {
  width: 15rem;
}

.radioflowdiv {
  max-height: 80%;
  overflow: auto;
  margin-top: 5%;
}

.tablename {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}

.divlblfont {
  font-size: 16px;
  line-height: 20.25px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

.radiodiv {
  border: solid #e1e1e1;
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.lblfontdark {
  color: #292929;
}

.lblfontlight {
  color: #999999;
  font-size: 16px;
  line-height: 20.25px;
  font-family: "Open Sans", sans-serif;
}

.el-radio__input.is-checked .el-radio__inner {
  border-color: #333333;
  background: #f2af00;
}

.el-loading-spinner .path {
  stroke-width: 4;
  stroke: #f2af00;
}
.tblabelfont {
  font-size: 16px;
  line-height: 20.25px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}

.labelfont {
  color: #292929;
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: "Open Sans", sans-serif;
}
.el-radio__inner {
  border: 1px solid #333333;
}

.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #f2af00;
  border-color: #f2af00;
}

.el-radio__input.is-checked + .el-radio__label {
  color: #333333;
  font-size: 16px;
  line-height: 20.25px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import APIService from "@/utils/APIService";
import { UserInformation, NotifiedUser } from "@/utils/AppInterfaces";
import _ from "lodash";
import { UserActionTypes } from "@/store/userstore/UserActionTypes";
import { CompanyActionTypes } from "@/store/companystore/CompanyActionTypes";

export default defineComponent({
  name: "AddCompanyAdminModal",
  computed: {
    ...mapGetters(["getUsersWithoutAdmins", "getCompanyData"]),
    existingUsers(): any {
      return !_.isEmpty(this.getUsersWithoutAdmins) ||
        this.getUsersWithoutAdmins.length !== 0
        ? this.getUsersWithoutAdmins
        : [];
    },
    companyData(): any {
      this.setCompanyName();
      return [
        {
          companyName: this.getCompanyData.companyName,
          companyValue: this.getCompanyData.companyName,
        },
      ];
    },
    userSearchValue: {
      get(): any {
        return this.searchUser;
      },
      set(val: string) {
        this.searchUser = val;
        this.loading = true;
        const requestObject = {
          userBucketIdList: null,
          companyBucketIdList: [this.getCompanyData.companyBucketId],
          searchPhrase: val,
          companyName: null,
          pageOptions: {
            skip: 0,
            take: 10,
            sortField: null,
            sortOrder: null,
          },
        };
        const apiService = new APIService(this.axios);
        if (val.length !== 0) {
          apiService.getUsersWithoutAdmins(requestObject).then((res) => {
            this.$store.dispatch(
              UserActionTypes.SET_USERS_WITHOUTCOMPANYADMIN,
              res.items
            );
            this.loading = false;
          });
        } else {
          requestObject.searchPhrase = "";
          apiService.getUsersWithoutAdmins(requestObject).then((res) => {
            this.$store.dispatch(
              UserActionTypes.SET_USERS_WITHOUTCOMPANYADMIN,
              res.items
            );
            this.loading = false;
          });
        }
      },
    },
  },
  data() {
    return {
      rowdatastyle: {
        color: "#333333",
      },
      selectAssignOption: "existingUser",
      showLightFont: true,
      displayOption: "radioSelection",
      loading: true,
      maxNameLength: 128,
      maxEmailLength: 256,
      selectedCompany: [this.getCompanyData],
      inviteData: {
        firstName: "",
        lastName: "",
        email: "",
        companyBucketId: "",
        userBucketId: null,
        role: null,
        roleId: 4,
      },
      selectedUsers: [{} as UserInformation],
      noemail: false,
      hasError: false,
      showError: false,
      enableControls: true,
      notifiedUsers: [{} as NotifiedUser],
      searchUser: "",
      addUserError: "",
    };
  },
  methods: {
    closeModal() {
      this.selectedUsers = [];
      this.$store.dispatch(UserActionTypes.SET_USERS_WITHOUTCOMPANYADMIN, []);
      this.$store.dispatch(CompanyActionTypes.SET_SHOW_ADDCOMPANYADMIN, false);
    },
    handleSelectionChange(selected: any) {
      this.selectedUsers = selected;
      this.showError = false;
    },
    assignExisting() {
      this.displayOption = "existingUsers";
      const requestObject = {
        userBucketIdList: null,
        companyBucketIdList: [this.getCompanyData.companyBucketId],
        searchPhrase: "",
        companyName: null,
        pageOptions: {
          skip: 0,
          take: 10,
          sortField: null,
          sortOrder: null,
        },
      };
      const apiService = new APIService(this.axios);
      apiService.getUsersWithoutAdmins(requestObject).then((res) => {
        this.$store.dispatch(
          UserActionTypes.SET_USERS_WITHOUTCOMPANYADMIN,
          res.items
        );
        this.loading = false;
      });
    },
    setCompanyName() {
      this.selectedCompany = this.getCompanyData.companyName;
    },
    assignNewUser() {
      this.displayOption = "newUser";
    },
    emailValidation(email: string) {
      const pattern = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9._+-]+\.[a-zA-Z]{2,4}$/;
      this.noemail = !pattern.test(email);
      return this.noemail;
    },
    selectionChanged() {
      this.showLightFont = this.selectAssignOption !== "newUser";
    },
    nextClickHandler() {
      let inviteObj = null;
      this.inviteData.companyBucketId =
        this.getCompanyData.companyBucketId || null;
      inviteObj = this.inviteData;

      if (this.displayOption === "newUser") {
        if (
          inviteObj.firstName === "" ||
          inviteObj.lastName === "" ||
          inviteObj.email === ""
        ) {
          this.hasError = true;
          this.noemail = false;
        } else {
          this.hasError = false;
          if (!this.emailValidation(inviteObj.email))
            this.addNewUser(inviteObj);
        }
      } else {
        const userBucketIds: string[] = [];
        if (this.selectedUsers.length !== 0) {
          this.selectedUsers.map((user) =>
            userBucketIds.push(user.userBucketId)
          );
          this.addExistingUser(userBucketIds);
        } else {
          this.showError = true;
          this.addUserError = "Please select the user!";
        }
      }
    },
    addExistingUser(userBucketIds: string[]) {
      const roleData = {
        roleId: 4,
        userBucketIdCollection: userBucketIds,
      };
      this.enableControls = false;
      const apiService = new APIService(this.axios);
      apiService.addRoleToUsers(roleData).then((res) => {
        if (res.success) {
          this.displayOption = "emailSent";
          this.showError = false;
          apiService
            .getCompLicAdmins(this.getCompanyData.companyBucketId)
            .then((resp) => {
              this.$store.dispatch(
                CompanyActionTypes.SET_AVAILABLE_LICADMINS,
                resp
              );
            });
        } else {
          this.showError = true;
          // eslint-disable-next-line prefer-destructuring
          this.addUserError = res.validationResult.validationMessages;
        }
        this.enableControls = true;
      });
    },
    addNewUser(invteobj: any) {
      this.enableControls = false;
      const apiService = new APIService(this.axios);
      apiService.addNewCompanyAdmin(invteobj).then((res) => {
        if (res.success) {
          this.displayOption = "emailSent";
          this.showError = false;
          apiService
            .getCompLicAdmins(this.getCompanyData.companyBucketId)
            .then((resp) => {
              this.$store.dispatch(
                CompanyActionTypes.SET_AVAILABLE_LICADMINS,
                resp
              );
            });
          this.notifiedUsers.push({
            name: `${this.inviteData.firstName} ${this.inviteData.lastName}`,
            email: this.inviteData.email,
          });
        } else {
          this.showError = true;
          // eslint-disable-next-line prefer-destructuring
          this.addUserError = `${res.validationMessages[0]} ${res.validationMessages[1]}`;
        }
        this.enableControls = true;
      });
    },
  },
});
</script>
