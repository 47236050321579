export enum UserActionTypes {
  SET_USERS = "SET_USERS",
  SET_ASSIGNED_PRODUCTS = "SET_ASSIGNED_PRODUCTS",
  SET_CURRENTUSER = "SET_CURRENTUSER",
  SET_USER_ADDONS = "SET_USER_ADDONS",
  SET_USERS_WITHOUTCOMPANYADMIN = "SET_USERS_WITHOUTCOMPANYADMIN",
  SET_COMPANY_USERS = "SET_COMPANY_USERS",
  SET_UNASSIGNED_SUBSCRIPTIONUSERS = "SET_UNASSIGNED_SUBSCRIPTIONUSERS",
  SET_USER_ERROR = "SET_USER_ERROR",
}
