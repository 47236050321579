<template>
  <!-- <div> -->
  <el-button
    class="linkbtn"
    :loading="loading"
    @click="openReleaseNotes"
    v-if="userAuthenticated"
  >
    <el-link class="linkbtn" id="releasenotes">
      <img
        src="@/assets/icons/licenseicon.svg"
        style="width: 24px; margin-right: 10px"
      />
      Release Notes
    </el-link>
  </el-button>
  <el-button class="linkbtn">
    <el-link
      class="linkbtn"
      href="https://support.visualcomponents.com"
      target="_blank"
      id="support"
    >
      <img
        src="@/assets/icons/support.svg"
        style="width: 24px; margin-right: 10px"
      />
      Support
    </el-link>
  </el-button>
  <el-button class="linkbtn">
    <el-link
      class="linkbtn"
      href="https://forum.visualcomponents.com/"
      target="_blank"
      id="forum"
    >
      <img
        src="@/assets/icons/forum.svg"
        style="width: 24px; margin-right: 10px"
      />
      Forum
    </el-link>
  </el-button>
  <el-button class="linkbtn">
    <el-link
      class="linkbtn"
      href="https://academy.visualcomponents.com"
      target="_blank"
      id="tutorial"
    >
      <img
        src="@/assets/icons/tutorial.svg"
        style="width: 24px; margin-right: 10px"
      />
      Tutorial
    </el-link>
  </el-button>
  <!-- </div> -->
</template>

<style lang="scss">
.linkbtn {
  color: #333333;
  background: unset;
  border: unset;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-decoration: underline;
  display: flex;
  margin: 12px 0px !important;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import APIService from "@/utils/APIService";
import { useIsAuthenticated } from "../composition-api/useIsAuthenticated";

export default defineComponent({
  name: "UsefulLinks",
  computed: {
    ...mapGetters(["getReleaseNotes"]),
    userAuthenticated(): boolean {
      return useIsAuthenticated().value;
    },
  },
  data() {
    return {
      loading: false,
      releaseNotesUrl: "",
    };
  },
  methods: {
    openReleaseNotes() {
      if (!this.releaseNotesUrl) {
        this.loading = true;
        const apiService = new APIService(this.axios);
        apiService.getReleaseNotes().then((response: any) => {
          const blob = new Blob([response], { type: "application/pdf" });
          this.releaseNotesUrl = URL.createObjectURL(blob);
          this.loading = false;
          window.open(this.releaseNotesUrl, "_blank");
        });
      } else {
        window.open(this.releaseNotesUrl, "_blank");
      }
    },
  },
});
</script>
