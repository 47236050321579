<template>
  <div class="errcontainerdiv">
    <div class="errorinnerdiv">
      <div class="errortextdiv">
        <img src="@/assets/icons/erroricon.svg" class="imagestyle" />
        <h4><b> Sorry, page not found (404). </b></h4>
      </div>
      <div class="normalerrortext">
        <h6>
          Please click
          <el-link href="/account" target="_self" type="warning" id="homelink"
            >here</el-link
          >
          to go to overview page.
        </h6>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.errortextdiv {
  display: flex;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 27.24px;
  margin-bottom: 2rem;
}
.normalerrortext {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.errorinnerdiv {
  box-sizing: border-box;
  background: #ffffff;
  padding: 5rem;
  height: 30rem;
  width: 45rem;
  display: flex;
  flex-direction: column;
}
.errcontainerdiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}
.imagestyle {
  width: 70px;
  height: 70px;
}
</style>

<script lang="ts">
import { defineComponent } from "vue";
import { AuthActionTypes } from "@/store/authstore/AuthActionTypes";

export default defineComponent({
  name: "PageNotFound",
  components: {},
  data() {
    return {};
  },
  created() {
    this.$store.dispatch(AuthActionTypes.SET_ERROR, true);
  },
});
</script>
