import { createStore } from "vuex";
import { flowstore } from "./flowstore/FlowModule";
import { productStore } from "./productstore/ProductModule";
import { userstore } from "./userstore/UserModule";
import { authstore } from "./authstore/AuthModule";
import { companystore } from "./companystore/CompanyModule";

export default createStore({
  modules: {
    flowstore,
    authstore,
    companystore,
    productStore,
    userstore,
  },
});
