<template>
  <h4 v-if="taskType === 'assign'" class="modal-title" id="asignusertitle">
    ASSIGN USERS
  </h4>
  <div
    class="innerModal"
    v-if="taskType === 'assign' || taskType === 'account'"
  >
    <label
      v-if="taskType === 'assign' || taskType === 'account'"
      class="bottommargin titlestyle"
      >Select available users</label
    >
    <el-input
      id="assignusersearch"
      v-model="userSearchValue"
      placeholder="Search"
      type="search"
      debounce="1000"
      class="rightmargin inpstyle bottommargin"
      v-if="taskType === 'assign' || taskType === 'account'"
    >
      <template #suffix>
        <img src="@/assets/icons/search.svg" style="width: 25px" />
      </template>
    </el-input>
    <div
      :class="
        !showError
          ? 'useroverflowdiv heightnoerror'
          : 'heightwitherror useroverflowdiv'
      "
    >
      <el-table
        id="assignusertable"
        v-loading="loading"
        v-if="taskType === 'assign' || taskType === 'account'"
        ref="usertable"
        :data="userOptions"
        style="width: 100%"
        :header-cell-style="headerstyle"
        :row-style="rowdatastyle"
        @select="handleSelectionChange"
        @select-all="handleSelectionChange"
        @row-click="rowClickHandler"
        @sort-change="handleSorting"
        :default-sort="{ prop: 'name', order: 'ascending' }"
        empty-text="No users available"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column label="User Name" prop="name" sortable />
        <el-table-column label="Email" prop="email" />
      </el-table>
    </div>
    <div
      v-if="totalPages > 1 && (taskType === 'assign' || taskType === 'account')"
      :class="!showError ? 'usermodalpagingdiv' : 'usermodalpagingdivwitherror'"
    >
      <label class="pagelabel"
        ><strong>Page {{ currentPage }} / {{ totalPages }}</strong></label
      >
      <el-button
        plain
        class="paginationbtn"
        @click="currentPage = 1"
        id="firstpage"
        :disabled="currentPage === 1"
      >
        <img
          src="@/assets/icons/pagefirstenabled.svg"
          style="width: 20px"
          v-if="currentPage !== 1"
        />
        <img
          src="@/assets/icons/pagefirstdisabled.svg"
          style="width: 20px"
          v-if="currentPage === 1"
        />
      </el-button>
      <el-pagination
        layout="prev, next"
        :total="totalRowsCount"
        :page-size="10"
        v-model:current-page="currentPage"
      />
      <el-button
        plain
        class="paginationbtn"
        @click="currentPage = totalPages"
        id="lastpage"
        :disabled="currentPage === totalPages"
      >
        <img
          src="@/assets/icons/pagelastenabled.svg"
          style="width: 20px"
          v-if="currentPage !== totalPages"
        />
        <img
          src="@/assets/icons/pagelastdisabled.svg"
          style="width: 20px"
          v-if="currentPage === totalPages"
        />
      </el-button>
    </div>
    <div v-if="showError" class="maxitemsdiv">
      <img src="@/assets/icons/warnerror.svg" class="warnimagestyle" />
      <label
        class="errordiv"
        v-if="addUserError !== ''"
        style="padding: 1rem 0"
      >
        {{ addUserError }}</label
      >
      <label
        class="errordiv"
        v-if="addUserError === ''"
        style="padding: 1rem 0"
      >
        {{ errormsg }}</label
      >
    </div>
    <div class="buttonsdiv btnbottomdiv">
      <el-button
        id="assignusernext"
        round
        class="btnstyle ml-4"
        variant="warning"
        @click="nextClickHandler"
        :disabled="!enableControls || selectedUsers.length === 0"
        v-if="taskType === 'account'"
      >
        Next
      </el-button>
      <el-button
        id="assignuserinvite"
        round
        class="btnstyle ml-4"
        variant="warning"
        @click="AssignUserInvite"
        :disabled="selectedUsers.length === 0 || showError"
        v-if="taskType === 'assign'"
      >
        <i
          v-if="!enableControls && !showError"
          class="fa fa-refresh fa-spin"
        ></i>
        Send Invite
      </el-button>
      <el-button
        id="assignusercancel"
        round
        variant="outline-dark"
        @click="cancelInvite"
        class="cancelbtn"
        >Cancel</el-button
      >
    </div>
  </div>
  <div v-if="taskType === 'emailSent'" class="innerModal">
    <div class="labeldiv">
      <img
        src="@/assets/icons/mailsent.svg"
        style="width: 70px; margin-bottom: 1rem"
      />
      <label class="labelbld">Notification email sent to: </label>
      <div
        v-for="(item, index) in notifiedUsers"
        :key="index"
        style="display: flex"
      >
        <p class="labelfont">
          <strong>{{ item.name }}</strong
          >&nbsp;<br />
          &#40;{{ item.email }} &#41;
        </p>
      </div>
    </div>
    <div class="buttonsdiv btnbottomdiv">
      <el-button
        id="assignuserdone"
        round
        class="btnstyle"
        variant="warning"
        @click="cancelInvite"
        style="float: right"
      >
        Done
      </el-button>
    </div>
  </div>
</template>

<style lang="scss">
.el-table__fixed-right-patch {
  background-color: #f2f2f2;
}

.headerstyle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.errordiv {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #df444d;
}

.maxitemsdiv {
  display: flex;
  align-items: center;
}

.btn-link {
  color: #000000;
}
.btnbottomdiv {
  bottom: 3%;
  position: fixed;
  width: 33%;
}

.warnimagestyle {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.heightwitherror {
  max-height: 60%;
}
.heightnoerror {
  max-height: 65%;
}

.userdatadiv {
  display: flex;
  flex-direction: column;
  position: fixed;
  max-height: 60%;
  overflow: auto;
}

.canbtn {
  font-size: 16px;
  line-height: 24px;
}
.el-select-dropdown__item.selected {
  font-size: 14px;
  color: #606266;
  line-height: 34px;
  font-weight: 300;
}
.withheading {
  padding: 10px 10px 2rem 3rem;
}
.withoutheading {
  padding: unset;
}

.el-select .el-input.is-focus .el-input__inner {
  border-color: #999999;
}

.btndiv {
  display: flex;
  flex-flow: row-reverse;
  bottom: 3rem;
  position: fixed;
  width: 80%;
}

.headlbl {
  color: #343a40;
  display: block !important;
}

.el-select > .el-input {
  display: block;
  width: 80%;
}

.cancelbtn {
  background: unset;
  border: 1px solid #333333;
  font-size: 0.8rem;
  line-height: 1rem;
  width: 140px !important;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}
.cancelbtn:hover {
  background: unset;
  color: #000000;
}

.checkbox-like {
  margin: auto;
  width: 1.25rem;
  height: 1.25rem;
  border: 0.2rem solid #666666;
  border-radius: 0.2rem;
  position: relative;
  &:after {
    content: "";
    display: block;
    width: 0.6rem;
    height: 1rem;
    transform: rotate(45deg);
    border: {
      bottom: 3px solid #666666;
      right: 3px solid #666666;
    }
    position: absolute;
    left: calc(50% - 0.3rem);
    top: calc(50% - 0.7rem);
    opacity: 0;
    transition: 0.2s;
  }
  &.checked {
    background-color: #f2af00;
    &:after {
      opacity: 1;
    }
  }
}

.usermodalpagingdiv {
  margin-top: 1.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.usermodalpagingdivwitherror {
  margin-top: 1.5rem;
  display: flex;
  justify-content: flex-end;
}

.fieldLength {
  font-style: italic;
  font-size: x-small;
  color: gray;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import APIService from "@/utils/APIService";
import { NotifiedUser, UserInformation } from "@/utils/AppInterfaces";
import _ from "lodash";
import { FlowActionTypes } from "@/store/flowstore/FlowActionTypes";
import { UserActionTypes } from "@/store/userstore/UserActionTypes";
import { ProductActionTypes } from "@/store/productstore/ProductActionTypes";
import { ElTable } from "element-plus";

export default defineComponent({
  name: "AssignUsersModal",
  components: {},
  computed: {
    ...mapGetters([
      "getProductData",
      "getSubscriptionData",
      "getTaskType",
      "getUnassignedSubscriptionUsers",
      "getCompanyData",
      "getCurrentUser",
      "getUserData",
    ]),
    userOptions(): any {
      if (_.isEmpty(this.getUnassignedSubscriptionUsers) && !this.loading)
        this.getUnassignedUsersList();

      if (!_.isEmpty(this.getUnassignedSubscriptionUsers))
        this.updatePaginationData();

      return !_.isEmpty(this.getUnassignedSubscriptionUsers)
        ? this.getUnassignedSubscriptionUsers.items
        : [];
    },
    taskType(): string {
      return this.getTaskType;
    },
    availableSeats(): number {
      let availableSeats = 0;
      if (!_.isEmpty(this.getProductData)) {
        availableSeats =
          this.getProductData.seats - this.getProductData.seatsUsed;
      } else if (!_.isEmpty(this.getSubscriptionData)) {
        availableSeats =
          this.getSubscriptionData.seats - this.getSubscriptionData.seatsUsed;
      }
      return availableSeats;
    },
    userSearchValue: {
      get(): string {
        return this.searchUser;
      },
      set(val: string) {
        this.searchUser = val;
      },
    },
  },
  data() {
    return {
      inviteData: {
        firstName: "",
        lastName: "",
        email: "",
        companyBucketId: "",
        userBucketId: null,
        role: null,
        roleId: 5,
      },
      selectedUsers: [] as any,
      emaildata: {},
      headerstyle: {
        "border-bottom": "unset",
        "font-family": "Open Sans, sans-serif",
        "font-weight": "700",
        "font-size": "0.8rem",
        "line-height": "1rem",
        color: "black",
      },
      rowdatastyle: {
        "font-family": "Open Sans, sans-serif",
        "font-weight": "400",
        "font-size": "0.8rem",
        "line-height": "1rem",
        color: "black",
      },
      enableControls: true,
      notifiedUsers: [] as NotifiedUser[],
      task: "",
      showError: false,
      loading: true,
      addUserError: "",
      errormsg:
        "Maximum license / seat is reached, no more user can be assigned to this product.",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pagesNumber: 1,
      totalPages: 0,
      totalRowsCount: 0,
      itemsOnPage: 0,
      searchUser: "",
      userval: "",
    };
  },
  watch: {
    currentPage: function watchForChanges(currPage, prevPage) {
      if (currPage !== prevPage) {
        this.loading = true;
        const apiService = new APIService(this.axios);
        const prodData = this.getProdData();
        const subData = {
          Searchphrase: this.searchUser,
          companyBucketId: this.getCompanyBucketId(),
          productBucketId: prodData.productBucketId,
          Major: prodData.majorVersionNumber,
          Minor: prodData.minorVersionNumber,
          Patch: prodData.patchVersionNumber,
          pageOptions: {
            skip: this.currentPage - 1,
            take: 10,
            sortField: null,
            sortOrder: null,
          },
        };
        apiService.getUnAssignedUsersSubList(subData).then((res) => {
          this.loading = false;
          this.$store.dispatch(
            UserActionTypes.SET_UNASSIGNED_SUBSCRIPTIONUSERS,
            res
          );
        });
      }
    },
    searchUser(val) {
      const prodData = this.getProdData();
      const subData = {
        searchPhrase: val as any,
        companyBucketId: this.getCompanyBucketId(),
        productBucketId: prodData.productBucketId,
        Major: prodData.majorVersionNumber,
        Minor: prodData.minorVersionNumber,
        Patch: prodData.patchVersionNumber,
        pageOptions: {
          skip: 0,
          take: 10,
          sortField: null,
          sortOrder: null,
        },
      };
      this.currentPage = 1;
      this.loading = true;
      const apiService = new APIService(this.axios);
      if (val.length !== 0) {
        this.checkSearchStr(subData);
      } else {
        subData.searchPhrase = null;
        apiService.getUnAssignedUsersSubList(subData).then((res) => {
          this.loading = false;
          this.$store.dispatch(
            UserActionTypes.SET_UNASSIGNED_SUBSCRIPTIONUSERS,
            res
          );
        });
      }
    },
  },
  methods: {
    checkSearchStr: _.debounce(function (this: any, dataobject: any) {
      this.loading = true;
      const apiService = new APIService(this.axios);
      apiService.getUnAssignedUsersSubList(dataobject).then((res) => {
        this.loading = false;
        this.$store.dispatch(
          UserActionTypes.SET_UNASSIGNED_SUBSCRIPTIONUSERS,
          res
        );
      });
    }, 1000),
    getProductId() {
      let id;
      if (!_.isEmpty(this.getProductData)) {
        id = this.getProductData.productBucketId;
      } else if (!_.isEmpty(this.getSubscriptionData)) {
        id = this.getSubscriptionData.productBucketId;
      }
      return id;
    },
    getProdData() {
      let prodData;
      if (!_.isEmpty(this.getProductData)) {
        prodData = this.getProductData;
      } else if (!_.isEmpty(this.getSubscriptionData)) {
        prodData = this.getSubscriptionData;
      }
      return prodData;
    },
    getUnassignedUsersList() {
      this.loading = true;
      const apiService = new APIService(this.axios);
      const prodData = this.getProdData();
      const subData = {
        Searchphrase: "",
        companyBucketId: this.getCompanyBucketId(),
        productBucketId: prodData.productBucketId,
        Major: prodData.majorVersionNumber,
        Minor: prodData.minorVersionNumber,
        Patch: prodData.patchVersionNumber,
        pageOptions: {
          skip: this.currentPage - 1,
          take: 10,
          sortField: null,
          sortOrder: null,
        },
      };
      apiService.getUnAssignedUsersSubList(subData).then((res) => {
        this.$store.dispatch(
          UserActionTypes.SET_UNASSIGNED_SUBSCRIPTIONUSERS,
          res
        );
        this.loading = false;
      });
    },
    updatePaginationData() {
      this.currentPage =
        this.currentPage !== 0
          ? this.currentPage
          : this.getUnassignedSubscriptionUsers.pagingData.currentPage;
      this.totalPages =
        this.getUnassignedSubscriptionUsers.pagingData.totalPages;
      this.totalRowsCount =
        this.getUnassignedSubscriptionUsers.pagingData.itemCount;
    },
    AssignUserInvite() {
      this.enableControls = false;
      let invteobj = null;
      const prodata = this.getProdData();
      const apiService = new APIService(this.axios);
      this.selectedUsers.map((user: UserInformation) => {
        if (this.taskType === "assign") {
          invteobj = {
            companyBucketId: user.companyBucketId,
            userBucketId: user.userBucketId,
            entitlementsToAssign: [
              {
                productBucketId: prodata.productBucketId,
                Major: prodata.majorVersionNumber,
                Minor: prodata.minorVersionNumber,
                Patch: prodata.patchVersionNumber,
              },
            ],
          };
          if (!_.isEmpty(this.getProductData)) {
            apiService.assignSubscription(invteobj).then((res) => {
              if (res.assignmentResult.success) {
                const prodInfo = {
                  productBucketId: prodata.productBucketId,
                  majorVersionNumber: prodata.majorVersionNumber,
                  minorVersionNumber: prodata.minorVersionNumber,
                  patchVersionNumber: prodata.patchVersionNumber,
                };
                apiService.getProduct(prodInfo).then((prod) => {
                  this.$store.dispatch(
                    FlowActionTypes.SET_PRODUCT_DATA,
                    prod.items[0]
                  );
                });
                apiService
                  .getAssignedUsersList(prodata.productBucketId, "", 0, 10)
                  .then((resp) => {
                    this.$store.dispatch(
                      ProductActionTypes.SET_ASSIGNED_USERS,
                      resp
                    );
                  });
                this.notifiedUsers.push({
                  name: user.name,
                  email: user.email,
                });
                this.$store.dispatch(
                  FlowActionTypes.SET_TASK_TYPE,
                  "emailSent"
                );
              } else {
                this.showError = true;
                // eslint-disable-next-line prefer-destructuring
                // this.addUserError = res.validationResult.validationMessages[0];
              }
              this.enableControls = true;
            });
          } else if (!_.isEmpty(this.getSubscriptionData)) {
            apiService.assignSubscription(invteobj).then((res) => {
              if (res.assignmentResult.success) {
                const subInfo = {
                  productBucketId: this.getSubscriptionData.productBucketId,
                  majorVersionNumber:
                    this.getSubscriptionData.majorVersionNumber,
                  minorVersionNumber:
                    this.getSubscriptionData.minorVersionNumber,
                  patchVersionNumber:
                    this.getSubscriptionData.patchVersionNumber,
                };
                apiService.getProduct(subInfo).then((prod) => {
                  this.$store.dispatch(
                    FlowActionTypes.SET_SUBSCRIPTION_DATA,
                    prod.items[0]
                  );
                });
                const subData = {
                  Searchphrase: "",
                  productBucketId: this.getSubscriptionData.productBucketId,
                  Major: this.getSubscriptionData.majorVersionNumber,
                  Minor: this.getSubscriptionData.minorVersionNumber,
                  Patch: this.getSubscriptionData.patchVersionNumber,
                  pageOptions: {
                    skip: this.currentPage - 1,
                    take: 10,
                    sortField: null,
                    sortOrder: null,
                  },
                };
                apiService.getAssignedUsersSubList(subData).then((resp) => {
                  this.$store.dispatch(
                    ProductActionTypes.SET_ASSIGNED_USERS,
                    resp
                  );
                });
                this.notifiedUsers.push({
                  name: user.name,
                  email: user.email,
                });
                this.$store.dispatch(
                  FlowActionTypes.SET_TASK_TYPE,
                  "emailSent"
                );
              } else {
                this.showError = true;
                // eslint-disable-next-line prefer-destructuring
                // this.addUserError = res.validationResult.validationMessages[0];
              }
            });
          }
        }
        return !this.enableControls;
      });
    },
    handleSelectionChange(selected: any) {
      if (selected && this.availableSeats < selected.length) {
        this.showError = true;
        this.addUserError = "";
        this.enableControls = false;
      } else {
        this.showError = false;
        this.enableControls = true;
        this.selectedUsers = selected;
        if (this.taskType === "assign" || this.taskType === "account") {
          this.$store.dispatch(
            FlowActionTypes.SET_SELECTED_EMAILS,
            this.selectedUsers
          );
          this.$store.dispatch(FlowActionTypes.SET_SELECTED_USERS, selected);
        }
      }
    },
    rowClickHandler(clickedRow: any) {
      if (_.some(this.selectedUsers, clickedRow)) {
        (this.$refs["usertable"] as typeof ElTable).toggleRowSelection(
          clickedRow
        );
        _.remove(this.selectedUsers, clickedRow);
      } else {
        this.selectedUsers.push(clickedRow);
        this.$store.dispatch(
          FlowActionTypes.SET_SELECTED_USERS,
          this.selectedUsers
        );
        (this.$refs["usertable"] as typeof ElTable).toggleRowSelection(
          clickedRow
        );
      }

      this.selectedUsers =
        this.selectedUsers.length === 0 ? [] : this.selectedUsers;

      if (this.availableSeats < this.selectedUsers.length) {
        this.showError = true;
      } else {
        this.showError = false;
      }
    },
    // eslint-disable-next-line no-unused-vars
    handleSorting({
      //   column,
      prop,
      order,
    }: {
      //   column: any;
      prop: any;
      order: any;
    }) {
      const sorttingField = prop === "name" ? 1 : 0;
      const prodData = this.getProdData();
      const subData = {
        Searchphrase: "",
        companyBucketId: this.getCompanyBucketId(),
        productBucketId: prodData.productBucketId,
        Major: prodData.majorVersionNumber,
        Minor: prodData.minorVersionNumber,
        Patch: prodData.patchVersionNumber,
        pageOptions: {
          skip: 0,
          take: 10,
          sortField: sorttingField,
          sortOrder: order === "ascending" ? 0 : 1,
        },
      };
      this.$store.dispatch(
        UserActionTypes.SET_UNASSIGNED_SUBSCRIPTIONUSERS,
        {}
      );
      this.currentPage = 1;
      this.loading = true;
      const apiService = new APIService(this.axios);
      apiService.getUnAssignedUsersSubList(subData).then((res) => {
        this.loading = false;
        this.$store.dispatch(
          UserActionTypes.SET_UNASSIGNED_SUBSCRIPTIONUSERS,
          res
        );
      });
    },
    nextClickHandler() {
      this.$store.dispatch(FlowActionTypes.SET_TASK_TYPE, "summary");
      this.userval = "";
    },
    getCompanyBucketId() {
      let companyId = "";
      switch (this.$route.name) {
        case "account":
          companyId = this.getCurrentUser.result.companyBucketId;
          break;
        case "companies":
          companyId = this.getCompanyData.companyBucketId;
          break;
        case "user":
          companyId = this.getUserData.companyBucketId;
          break;
        case "product":
          companyId = "";
          break;
        default:
      }
      return companyId;
    },
    cancelInvite() {
      this.inviteData = {
        firstName: "",
        lastName: "",
        email: "",
        companyBucketId: "",
        userBucketId: null,
        role: null,
        roleId: 5,
      };
      this.userval = "";
      this.showError = false;
      this.addUserError = "";
      this.selectedUsers = [];
      this.notifiedUsers = [];
      this.$store.dispatch(FlowActionTypes.SET_SHOW_ADDPRODUCTS, false);
      this.$store.dispatch(FlowActionTypes.SET_SHOW_ADDUSER, false);
      this.$store.dispatch(FlowActionTypes.SET_SELECTED_USERS, []);
      if (this.$route.name !== "product")
        this.$store.dispatch(FlowActionTypes.SET_PRODUCT_DATA, {});
      // ^ otherwise user is sent back to products overview page instead of details page
      this.$store.dispatch(FlowActionTypes.SET_TASK_TYPE, "none");
      this.$store.dispatch(ProductActionTypes.SET_AVAILABLE_PRODUCTS, {
        items: [],
      });
      this.$store.dispatch(
        UserActionTypes.SET_UNASSIGNED_SUBSCRIPTIONUSERS,
        {}
      );
    },
  },
  mounted() {
    this.getUnassignedUsersList();
  },
  props: {
    showheading: Boolean,
  },
});
</script>
