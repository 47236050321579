<template>
  <div class="controltextstyle" style="margin-top: 3rem">
    <h5 class="font-bold-24">Assigned Users</h5>
    <div class="utils btm-2">
      <el-input
        id="productsearch"
        v-model="prdSearchVal"
        placeholder="Search"
        type="search"
        debounce="1000"
        class="rightmargin inpstyle"
        v-if="showOptions"
      >
        <template #suffix>
          <img src="@/assets/icons/search.svg" style="width: 25px" />
        </template>
      </el-input>
      <el-button
        plain
        class="delbtn"
        @click="assignUsers"
        id="assignprodtouser"
      >
        <img src="@/assets/icons/adduser.svg" style="width: 40px" />
      </el-button>
      <el-button
        plain
        class="delbtn"
        @click="assignAddon"
        id="addignaddon"
        v-if="showOptions"
        :disabled="!enablebuttons"
      >
        <img
          src="@/assets/icons/addaddonenabled.svg"
          style="width: 40px"
          v-if="enablebuttons"
        />
        <img
          src="@/assets/icons/addaddondisabled.svg"
          style="width: 40px"
          v-if="!enablebuttons"
        />
      </el-button>
      <el-button
        plain
        class="delbtn"
        @click="removeAddon"
        id="removeaddon"
        v-if="showOptions"
        :disabled="!enablebuttons && DisableDeleteAddon"
      >
        <img
          src="@/assets/icons/deladdonenabled.svg"
          style="width: 40px"
          v-if="enablebuttons && !DisableDeleteAddon"
        />
        <img
          src="@/assets/icons/deladdondisabled.svg"
          style="width: 40px"
          v-if="DisableDeleteAddon"
        />
      </el-button>
      <el-button
        plain
        class="delbtn"
        @click="removeEntitlement"
        id="removeentitlement"
        v-if="showOptions"
        :disabled="!enablebuttons"
      >
        <img
          src="@/assets/icons/deletedisabled.svg"
          style="width: 40px"
          v-if="!enablebuttons"
        />
        <img
          src="@/assets/icons/delete.svg"
          style="width: 40px"
          v-if="enablebuttons"
        />
      </el-button>

      <!-- <el-dropdown
        variant="link"
        toggle-class="text-decoration-none"
        offset="20"
        right
        no-caret
        class="optbtn"
        style="padding: unset"
      >
        <template v-slot:button-content>
          <img src="@/assets/icons/options.svg" style="width: 40px" />
        </template>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item
              @click="deactivateLic"
              id="dealic"
              v-if="ctrlTo === 'product'"
            >
              Deactivate the license
            </el-dropdown-item>
            <el-dropdown-item
              @click="deactivateLic"
              id="deausr"
              v-if="ctrlTo === 'user'"
            >
              Deactivate the user
            </el-dropdown-item>
            <el-dropdown-item
              @click="activateLic"
              id="actlic"
              v-if="ctrlTo === 'product'"
            >
              Activate the license
            </el-dropdown-item>
            <el-dropdown-item
              @click="activateLic"
              id="actusr"
              v-if="ctrlTo === 'user'"
            >
              Activate the user
            </el-dropdown-item>
            <el-dropdown-item disabled id="opts"> ... </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown> -->
    </div>
    <el-drawer
      v-model="showAdduser"
      direction="rtl"
      size="40%"
      destroy-on-close
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :wrapperClosable="false"
    >
      <AssignUsersModal v-if="showAssignUserModal" :showheading="true" />
    </el-drawer>
    <el-drawer
      v-model="showAssignAddon"
      direction="rtl"
      size="40%"
      destroy-on-close
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :wrapperClosable="false"
    >
      <AssignAddonModal />
    </el-drawer>
    <el-drawer
      v-model="showRemoveAddonsList"
      direction="rtl"
      size="40%"
      destroy-on-close
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :wrapperClosable="false"
    >
      <RemoveAddonsModal />
    </el-drawer>
    <!-- <el-dialog
    title="Remove add-on"
    v-model="showDeleteDailogue"
    width="35%"
    top="25vh"
    center
  >
    <el-divider></el-divider>
    <div class="text-div font-regular-16">
      <span
        ><strong>{{ getSelectedUserids.length }}</strong> Users selected
      </span>
      <br />
      <div v-for="(addon, index) in props.row.addOns" :key="index">
          {{ addon.productName }}
        </div>
      <span>All user data and subscriptions will be permanently removed.</span>
      <span>This action may not be undone.</span>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          id="deleteusercancel"
          @click="showDeleteDailogue = false"
          round
          class="cancelbtn"
          variant="outline-dark"
          >Cancel</el-button
        >
        <el-button
          type="danger"
          @click="deleteUser"
          round
          class="deletebutton"
          id="deleteuserconfirm"
        >
          Confirm
        </el-button>
      </span>
    </template>
  </el-dialog> -->
  </div>
</template>

<style lang="scss">
.input-group {
  margin-right: 2rem;
  border-bottom: 1px solid black;
}

.input-group-append {
  margin: 25px 0 auto 0;
}

.searchbtn {
  padding: unset;
}

.userutils {
  position: absolute;
  top: 27%;
  right: 5vw;
  display: flex;
  flex-direction: row;
}
.controltextstyle {
  // position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.usercontroltext {
  position: absolute;
  top: 27%;
  left: 5vw;
  display: flex;
  flex-direction: row;
}
.optrow {
  flex-direction: column;
  text-align: start;
  padding: 3% 0 1% 3%;
}
.delbtn {
  color: #000000;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  padding: unset;
}
.el-drawer__header {
  margin: unset;
}
.el-button--danger {
  color: #fff;
  background-color: #df444d;
  border-color: #df444d;
  font-family: "Open Sans", sans-serif;
  min-width: 10rem;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.el-button--default {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  min-width: 10rem;
}
.el-dialog__title {
  font-weight: 700;
  line-height: 24px;
  font-size: 18px;
  color: #303133;
}
.el-drawer__body {
  text-align: left;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import { ElMessage } from "element-plus";
import APIService from "@/utils/APIService";
import AssignUsersModal from "./AssignUsersModal.vue";
import AssignAddonModal from "./AssignAddonModal.vue";
import RemoveAddonsModal from "./RemoveAddonsModal.vue";
import { mapGetters } from "vuex";
import _ from "lodash";
import { ProductActionTypes } from "@/store/productstore/ProductActionTypes";
import { FlowActionTypes } from "@/store/flowstore/FlowActionTypes";

export default defineComponent({
  name: "ProdSubOptions",
  components: {
    AssignUsersModal,
    AssignAddonModal,
    RemoveAddonsModal,
  },
  computed: {
    ...mapGetters([
      "getProductData",
      "getSubscriptionData",
      "getShowAdduser",
      "getCurrentUser",
      "getSelectedUserids",
      "getCurrentPage",
      "getAssignedUserList",
      "getAddonUsers",
      "getShowAssignAddon",
      "getShowRemoveAddon",
      "getSelectedUsers",
    ]),
    prdSearchVal: {
      get(): string {
        return this.prdSearcher;
      },
      set(val: string) {
        this.prdSearcher = val;
      },
    },
    currentPage(): number {
      return this.getCurrentPage;
    },
    currentUserRole(): string {
      return _.isEmpty(this.getCurrentUser)
        ? ""
        : this.getCurrentUser.result.currentrole;
    },
    ctrlText(): string | undefined {
      return this.controlText;
    },
    ctrlTo(): string | undefined {
      return this.controlTo;
    },
    showAdduser: {
      get(): boolean {
        return this.getShowAdduser;
      },
      set() {
        this.$store.dispatch(FlowActionTypes.SET_SHOW_ADDUSER, false);
      },
    },
    showAssignAddon: {
      get(): boolean {
        return this.getShowAssignAddon;
      },
      set() {
        this.$store.dispatch(FlowActionTypes.SET_SHOW_ASSIGNADDON, false);
      },
    },
    showRemoveAddonsList: {
      get(): boolean {
        return this.getShowRemoveAddon;
      },
      set() {
        this.$store.dispatch(FlowActionTypes.SET_SHOW_REMOVEADDON, false);
      },
    },
    showOptions(): boolean {
      // let showuserptions = true;
      const totaldata = !_.isEmpty(this.getAssignedUserList)
        ? this.getAssignedUserList.pagingData.totalPages
        : 0;
      return totaldata !== 0 || this.prdSearchVal !== "";
    },
    DisableDeleteAddon(): boolean {
      return this.getAddonUsers.length === 0;
    },
    enablebuttons(): boolean {
      return this.getSelectedUserids.length !== 0;
    },
  },
  data() {
    return {
      prdSearcher: "",
      compSearcher: "",
      showSubscription: false,
      showDeleteDailogue: false,
      userHasAddon: false,
      showAssignUserModal: this.$route.name !== "user",
    };
  },
  watch: {
    prdSearcher(val) {
      const apiService = new APIService(this.axios);
      // apiService.setAssignedUsers(this.setAssUsers);
      if (val.length !== 0) {
        if (!_.isEmpty(this.getProductData)) {
          apiService
            .getAssignedUsersList(
              this.getProductData.productBucketId,
              val,
              0,
              10
            )
            .then((res) =>
              this.$store.dispatch(ProductActionTypes.SET_ASSIGNED_USERS, res)
            );
        } else if (!_.isEmpty(this.getSubscriptionData)) {
          const subData = {
            Searchphrase: val,
            productBucketId: this.getSubscriptionData.productBucketId,
            Major: this.getSubscriptionData.majorVersionNumber,
            Minor: this.getSubscriptionData.minorVersionNumber,
            Patch: this.getSubscriptionData.patchVersionNumber,
            pageOptions: {
              skip: 0,
              take: 10,
              sortField: null,
              sortOrder: null,
            },
          };
          this.checkSearchStr(subData);
        }
      } else if (!_.isEmpty(this.getProductData)) {
        apiService
          .getAssignedUsersList(
            this.getProductData.productBucketId,
            null,
            0,
            10
          )
          .then((res) =>
            this.$store.dispatch(ProductActionTypes.SET_ASSIGNED_USERS, res)
          );
      } else if (!_.isEmpty(this.getSubscriptionData)) {
        this.showSubscription = true;
        const subData = {
          Searchphrase: "",
          productBucketId: this.getSubscriptionData.productBucketId,
          Major: this.getSubscriptionData.majorVersionNumber,
          Minor: this.getSubscriptionData.minorVersionNumber,
          Patch: this.getSubscriptionData.patchVersionNumber,
          pageOptions: {
            skip: 0,
            take: 10,
            sortField: null,
            sortOrder: null,
          },
        };
        apiService
          .getAssignedUsersSubList(subData)
          .then((res) =>
            this.$store.dispatch(ProductActionTypes.SET_ASSIGNED_USERS, res)
          );
      }
    },
  },
  methods: {
    checkSearchStr: _.debounce(function (this: any, dataobject: any) {
      const apiService = new APIService(this.axios);
      apiService
        .getAssignedUsersSubList(dataobject)
        .then((res) =>
          this.$store.dispatch(ProductActionTypes.SET_ASSIGNED_USERS, res)
        );
    }, 1000),
    getProductId() {
      let id;
      if (!_.isEmpty(this.getProductData)) {
        id = this.getProductData;
      } else if (!_.isEmpty(this.getSubscriptionData)) {
        id = this.getSubscriptionData;
      }
      return id;
    },
    assignUsers() {
      this.$store.dispatch(FlowActionTypes.SET_TASK_TYPE, "assign");
      this.$store.dispatch(FlowActionTypes.SET_SHOW_ADDUSER, true);
    },
    removeEntitlement() {
      const apiService = new APIService(this.axios);
      const prodData = this.getProductId();
      const multipleRequestData = [] as any[];
      this.getSelectedUserids.map((userid: string) => {
        const removeRequest = {
          userBucketId: userid,
          entitlementsToRemove: [
            {
              productBucketId: prodData.productBucketId,
              major: prodData.majorVersionNumber,
              minor: prodData.minorVersionNumber,
              patch: prodData.patchVersionNumber,
            },
          ],
        } as any;
        multipleRequestData.push(removeRequest);
      });

      if (!_.isEmpty(this.getProductData)) {
        apiService.removeEntitlements(multipleRequestData).then((res) => {
          if (res.areAllSuccessfull) {
            apiService
              .getAssignedUsersList(
                prodData.productBucketId,
                null,
                this.currentPage - 1,
                10
              )
              .then((resp) => {
                const prodInfo = {
                  productBucketId: prodData.productBucketId,
                  majorVersionNumber: prodData.majorVersionNumber,
                  minorVersionNumber: prodData.minorVersionNumber,
                  patchVersionNumber: prodData.patchVersionNumber,
                };
                apiService.getProduct(prodInfo).then((prod) => {
                  this.$store.dispatch(
                    FlowActionTypes.SET_PRODUCT_DATA,
                    prod.items[0]
                  );
                });
                this.$store.dispatch(
                  ProductActionTypes.SET_ASSIGNED_USERS,
                  resp
                );
              });
            ElMessage({
              message: "Entitlement has been removed successfully.",
              type: "success",
            });
          } else {
            ElMessage.error(res.validationResult.validationMessages[0]);
          }
        });
      } else if (!_.isEmpty(this.getSubscriptionData)) {
        apiService
          .removeMultipleSubscriptions(multipleRequestData)
          .then((res) => {
            if (res.areAllSuccessfull) {
              const subData = {
                Searchphrase: "",
                productBucketId: this.getSubscriptionData.productBucketId,
                Major: this.getSubscriptionData.majorVersionNumber,
                Minor: this.getSubscriptionData.minorVersionNumber,
                Patch: this.getSubscriptionData.patchVersionNumber,
                pageOptions: {
                  skip: 0,
                  take: 10,
                  sortField: null,
                  sortOrder: null,
                },
              };
              apiService.getAssignedUsersSubList(subData).then((resp) => {
                const subInfo = {
                  productBucketId: this.getSubscriptionData.productBucketId,
                  majorVersionNumber:
                    this.getSubscriptionData.majorVersionNumber,
                  minorVersionNumber:
                    this.getSubscriptionData.minorVersionNumber,
                  patchVersionNumber:
                    this.getSubscriptionData.patchVersionNumber,
                };
                apiService.getProduct(subInfo).then((prod) => {
                  this.$store.dispatch(
                    FlowActionTypes.SET_SUBSCRIPTION_DATA,
                    prod.items[0]
                  );
                });
                this.$store.dispatch(
                  ProductActionTypes.SET_ASSIGNED_USERS,
                  resp
                );
              });
              ElMessage({
                message: "Entitlement has been removed successfully.",
                type: "success",
              });
            } else {
              ElMessage.error(res.removalResult.errorMessages[0]);
            }
          });
      }
    },
    assignAddon() {
      this.$store.dispatch(FlowActionTypes.SET_TASK_TYPE, "assignaddon");
      this.$store.dispatch(FlowActionTypes.SET_SHOW_ASSIGNADDON, true);
    },
    removeAddon() {
      this.$store.dispatch(FlowActionTypes.SET_SHOW_REMOVEADDON, true);
    },
    deactivateLic() {
      console.log("deactivate license");
    },
    activateLic() {
      console.log("activate license");
    },
  },
  props: {
    controlText: String,
    controlTo: String,
  },
});
</script>
