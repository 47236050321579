<template>
  <DetailsFlow overview="Users" detail="User" />
  <UserSubDetails v-if="!showDetails" />
  <UsersOverview v-if="showDetails" />
  <UserDetails v-if="!showDetails" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import UsersOverview from "@/components/UsersOverview.vue";
import DetailsFlow from "@/components/DetailsFlow.vue";
import UserDetails from "@/components/UserDetails.vue";
import UserSubDetails from "@/components/UserSubDetails.vue";
import { mapGetters } from "vuex";
import _ from "lodash";

export default defineComponent({
  name: "UsersView",
  components: {
    UsersOverview,
    DetailsFlow,
    UserDetails,
    UserSubDetails,
  },
  computed: {
    ...mapGetters(["getUserData", "getCurrentUser"]),
    showDetails(): any {
      return _.isEmpty(this.getUserData);
    },
    currentUser(): any {
      return _.isEmpty(this.getCurrentUser);
    },
  },
});
</script>
