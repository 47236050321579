<template>
  <div class="errcontainer">
    <h1 style="color: #f2af00">Oops!</h1>
    <h4>Something went wrong, please contact</h4>
    <h4 style="color: #f2af00">support@visualcomponents.com</h4>
  </div>
</template>

<style lang="scss">
.errcontainer {
  box-sizing: border-box;
  flex-direction: column;
  background: #ffffff;
  margin: 10vh 20vw;
  padding: 1% 4vw;
  height: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ErrorMessage",
});
</script>
