import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "sidebardiv" }
const _hoisted_2 = { class: "sidebarnamediv" }
const _hoisted_3 = {
  class: "complabel bottommargin",
  style: {"margin-bottom":"0"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_UsefullLinks = _resolveComponent("UsefullLinks")!
  const _component_el_menu = _resolveComponent("el-menu")!

  return (_openBlock(), _createBlock(_component_el_menu, {
    mode: "vertical",
    ellipsis: false,
    "menu-trigger": "click",
    "default-active": "/account",
    onSelect: _ctx.handleSelect
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_menu_item, {
          index: "/account",
          class: "sidebarmenulabel",
          onClick: _ctx.tabClicked
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Overview ")
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"]),
        _createVNode(_component_el_menu_item, {
          index: "/products",
          class: "sidebarmenulabel",
          onClick: _ctx.tabClicked
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Products & Services ")
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"]),
        _createVNode(_component_el_menu_item, {
          index: "/users",
          class: "sidebarmenulabel",
          onClick: _ctx.tabClicked
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Users ")
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"]),
        (_ctx.userRole !== 'LicenseAdmin')
          ? (_openBlock(), _createBlock(_component_el_menu_item, {
              key: 0,
              index: "/companies",
              class: "sidebarmenulabel",
              onClick: _ctx.tabClicked
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Companies ")
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick"]))
          : _createCommentVNode("v-if", true)
      ]),
      _createVNode(_component_el_divider),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_el_button, {
          circle: "",
          class: "namebtn topmargin bottommargin",
          type: "warning"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.nameInitials), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.currentUserName.firstName) + " " + _toDisplayString(_ctx.currentUserName.lastName), 1 /* TEXT */)
      ]),
      _createVNode(_component_el_divider),
      _createVNode(_component_el_menu_item, {
        index: "4",
        class: "sidebarmenufont",
        onClick: _ctx.onmyaccount
      }, {
        default: _withCtx(() => [
          _createTextVNode(" My Profile ")
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"]),
      _createVNode(_component_el_divider),
      _createVNode(_component_el_menu_item, {
        index: "5",
        class: "sidebarmenufont",
        id: "logout",
        onClick: _ctx.onlogout
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Logout ")
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"]),
      _createVNode(_component_UsefullLinks)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onSelect"]))
}