<template>
  <h4 class="modal-title">SUBSCRIPTIONS DETAILS</h4>
  <div class="subdivstyle bottommargin">
    <label class="lblfontlight nomargin">Product:</label>
    <label class="labelbld nomargin">
      {{ subscription.productName }}&nbsp;
      {{ subscription.majorVersionNumber }}.{{
        subscription.minorVersionNumber
      }}
    </label>
  </div>
  <div class="subdivstyle bottommargin">
    <label class="lblfontlight nomargin">Licenses Used:</label>
    <label class="labelbld nomargin">
      {{ subscription.seatsUsed }}&#47;{{ subscription.seats }}
    </label>
  </div>
  <el-divider class="dividerstyle"></el-divider>
  <div class="submodalbody">
    <div :class="totalPages > 1 ? 'suboverflowdivpaging' : 'suboverflowdiv'">
      <el-table
        id="subassigneduserstable"
        v-loading="usersLoading"
        :data="usersList"
        style="width: 100%"
        tooltip-effect="dark"
        :row-style="rowdatastyle"
        :header-cell-style="headerstyle"
        @sort-change="handleSorting"
        empty-text="No Users Assigned"
      >
        <el-table-column
          label="User Name"
          prop="name"
          sortable
          show-overflow-tooltip
          width="230px"
        >
          <template #default="name">
            <div class="subdivstyle">
              <label class="collabelbld" style="margin-bottom: unset">{{
                name.row.userName
              }}</label>
              <label class="collabelregular" style="margin-bottom: unset">
                &#40;{{ name.row.email }} &#41;</label
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Status" align="center" prop="status">
          <template #default="comp">
            <img
              src="@/assets/icons/active.svg"
              style="width: 16px"
              v-if="comp.row.status === 'Assigned'"
            />
            <img
              src="@/assets/icons/inactive.svg"
              style="width: 16px"
              v-if="comp.row.status === 'inactive'"
            />
            <img
              src="@/assets/icons/pending.svg"
              style="width: 16px"
              v-if="comp.row.status === 'pending'"
            />
          </template>
        </el-table-column>
        <el-table-column label="Expiration Date" prop="expirationDate" sortable>
          <template #default="data">
            <!-- <label class="collabelregular">{{data.row.updatedDate}}</label> -->
            <div :class="data.row.toExpire ? 'expirationlabel' : ''">
              {{ data.row.expirationDate }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagingdiv bottommargin" v-if="totalPages > 1">
      <label class="pagelabel"
        ><strong>Page {{ currentPage }} / {{ totalPages }}</strong></label
      >
      <el-button
        plain
        class="paginationbtn"
        @click="currentPage = 1"
        id="firstpage"
        :disabled="currentPage === 1"
      >
        <img
          src="@/assets/icons/pagefirstenabled.svg"
          style="width: 20px"
          v-if="currentPage !== 1"
        />
        <img
          src="@/assets/icons/pagefirstdisabled.svg"
          style="width: 20px"
          v-if="currentPage === 1"
        />
      </el-button>
      <el-pagination
        layout="prev, next"
        :total="totalRowsCount"
        :page-size="10"
        v-model:current-page="currentPage"
      />
      <el-button
        plain
        class="paginationbtn"
        @click="currentPage = totalPages"
        id="lastpage"
        :disabled="currentPage === totalPages"
      >
        <img
          src="@/assets/icons/pagelastenabled.svg"
          style="width: 20px"
          v-if="currentPage !== totalPages"
        />
        <img
          src="@/assets/icons/pagelastdisabled.svg"
          style="width: 20px"
          v-if="currentPage === totalPages"
        />
      </el-button>
    </div>
  </div>

  <div class="buttonsdiv mt-3">
    <el-button
      id="subdetailclose"
      round
      variant="outline-dark"
      @click="closeModal"
      class="cancelbtn"
    >
      Close
    </el-button>
  </div>
  <!-- </div> -->
</template>

<style lang="scss">
.nomargin {
  margin: unset;
}
.subdivstyle {
  display: flex;
  flex-direction: column;
}

.modalpagingdiv {
  margin-top: 1.5rem;
  display: flex;
  bottom: 5rem;
  position: fixed;
  justify-content: flex-end;
  width: 36%;
}

.suboverflowdiv {
  //   position: fixed;
  max-height: 90%;
  overflow: auto;
  //   width: 35%;
}
.suboverflowdivpaging {
  //   position: fixed;
  max-height: 85%;
  overflow: auto;
  //   width: 35%;
}

.collabelbld {
  color: #292929;
  font-size: 0.8rem;
  line-height: 1rem;
  font-family: "Open Sans";
  font-weight: 700;
}
.collabelregular {
  color: #292929;
  font-size: 0.8rem;
  line-height: 1rem;
  font-family: "Open Sans";
  font-weight: 400;
}

.dividerstyle {
  height: 3px;
  background: #999999;
  margin-bottom: 1rem !important;
}
.page-link {
  color: #f2af00;
  font-weight: bolder;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import APIService from "@/utils/APIService";
import _ from "lodash";
import { mapGetters } from "vuex";
// import { CompanyActionTypes } from "@/store/companystore/CompanyActionTypes";
import { ProductActionTypes } from "@/store/productstore/ProductActionTypes";
import { ProductInfomation } from "@/utils/AppInterfaces";
import { FlowActionTypes } from "@/store/flowstore/FlowActionTypes";

export default defineComponent({
  name: "rSubscriptionDetailModal",
  computed: {
    ...mapGetters([
      "getSubscriptionData",
      "getAssignedUserList",
      "getCompanyData",
    ]),
    subscription(): ProductInfomation {
      return this.getSubscriptionData;
    },
    usersList() {
      if (!_.isEmpty(this.getAssignedUserList)) {
        this.updatePaginationData();
      }
      const assignedUsers: any[] = [];
      if (!_.isEmpty(this.getAssignedUserList)) {
        // eslint-disable-next-line array-callback-return
        this.getAssignedUserList.items.map((item: any) => {
          const ddateToCheck = new Date();
          let toExpire = false;
          ddateToCheck.setDate(ddateToCheck.getDate() + 30);
          const expirationDate = new Date(item.expirationDate);
          if (expirationDate.getTime() < ddateToCheck.getTime())
            toExpire = true;
          assignedUsers.push({ ...item, toExpire });
        });
      }
      return assignedUsers;
    },
  },
  data() {
    return {
      usersLoading: true,
      headerstyle: {
        "border-bottom": "unset",
        "font-family": "Open Sans, sans-serif",
        "font-weight": "700",
        "font-size": "0.8rem",
        "line-height": "1rem",
        color: "black",
      },
      rowdatastyle: {
        background: "#F2F2F2",
        "font-family": "Open Sans, sans-serif",
        "font-weight": "400",
        "font-size": "0.8rem",
        "line-height": "1rem",
        color: "black",
        "border-bottom": "2px solid #FFFFF",
      },
      totalRows: 1,
      perPage: 10,
      currentPage: 1,
      pagesNumber: 1,
      totalPages: 0,
      totalRowsCount: 0,
      itemsOnPage: 0,
      selectedUsers: [],
    };
  },
  watch: {
    currentPage: function watchForChanges(currPage, prevPage) {
      if (currPage !== prevPage) {
        this.usersLoading = true;
        const apiService = new APIService(this.axios);
        if (!_.isEmpty(this.getSubscriptionData)) {
          //   this.showSubscription = true;
          const subData = {
            Searchphrase: "",
            productBucketId: this.getSubscriptionData.productBucketId,
            CompanyBucketId: this.getCompanyData.companyBucketId,
            Major: this.getSubscriptionData.majorVersionNumber,
            Minor: this.getSubscriptionData.minorVersionNumber,
            Patch: this.getSubscriptionData.patchVersionNumber,
            pageOptions: {
              skip: currPage - 1,
              take: 10,
              sortField: null,
              sortOrder: null,
            },
          };
          apiService.getAssignedUsersSubList(subData).then((res) => {
            this.usersLoading = false;
            this.$store.dispatch(ProductActionTypes.SET_ASSIGNED_USERS, res);
          });
        }
      }
    },
  },
  methods: {
    closeModal() {
      this.selectedUsers = [];
      this.$store.dispatch(FlowActionTypes.SET_SUBSCRIPTION_DATA, {});
      this.$store.dispatch(ProductActionTypes.SET_ASSIGNED_USERS, []);
      this.$store.dispatch(
        ProductActionTypes.SET_SHOW_SUBSCRIPTIONDETAILS,
        false
      );
    },
    updatePaginationData() {
      this.currentPage =
        this.currentPage !== 0
          ? this.currentPage
          : this.getAssignedUserList.pagingData.currentPage;
      this.totalPages = this.getAssignedUserList.pagingData.totalPages;
      this.totalRowsCount = this.getAssignedUserList.pagingData.itemCount;
    },
    getAssignedUsers() {
      const subscriptionData = this.subscription;
      const subData = {
        Searchphrase: "",
        productBucketId: subscriptionData.productBucketId,
        CompanyBucketId: this.getCompanyData.companyBucketId,
        Major: subscriptionData.majorVersionNumber,
        Minor: subscriptionData.minorVersionNumber,
        Patch: subscriptionData.patchVersionNumber,
        pageOptions: {
          skip: 0,
          take: 10,
          sortField: null,
          sortOrder: null,
        },
      };
      const apiService = new APIService(this.axios);
      apiService.getAssignedUsersSubList(subData).then((res) => {
        this.usersLoading = false;
        this.$store.dispatch(ProductActionTypes.SET_ASSIGNED_USERS, res);
      });
    },
    // eslint-disable-next-line no-unused-vars
    handleSorting({ prop, order }: { prop: any; order: any }) {
      const subscriptionData = this.subscription;
      // eslint-disable-next-line no-nested-ternary
      const sorttingField =
        prop === "userName" ? 1 : prop === "updatedDate" ? 24 : 0;
      const subData = {
        Searchphrase: "",
        productBucketId: subscriptionData.productBucketId,
        Major: subscriptionData.majorVersionNumber,
        Minor: subscriptionData.minorVersionNumber,
        Patch: subscriptionData.patchVersionNumber,
        pageOptions: {
          skip: this.currentPage - 1,
          take: 10,
          sortField: sorttingField,
          sortOrder: order === "ascending" ? 0 : 1,
        },
      };
      this.$store.dispatch(ProductActionTypes.SET_ASSIGNED_USERS, {});
      this.currentPage = 1;
      this.usersLoading = true;
      const apiService = new APIService(this.axios);
      apiService.getAssignedUsersSubList(subData).then((res) => {
        this.usersLoading = false;
        this.$store.dispatch(ProductActionTypes.SET_ASSIGNED_USERS, res);
      });
    },
  },
  mounted() {
    this.getAssignedUsers();
  },
});
</script>
