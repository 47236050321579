<template>
  <h4 class="modal-title" id="addusertitle">ADD NEW USER</h4>
  <div class="modalbody" v-if="taskType === 'add'">
    <div>
      <el-form label-width="auto" label-position="top" size="default">
        <el-form-item label="Company" class="firstLetterCapital">
          <el-select-v2
            ref="select"
            :options="compOptions"
            id="usercompanyselect"
            v-model="companyInfo"
            value-key="companyBucketId"
            class="user-inputstyle"
            placeholder="Select Company"
            remote
            :loading="loading"
            size="large"
            v-if="!showUserCompany"
          >
            <template #loading>
              <svg class="circular" viewBox="0 0 50 50">
                <circle class="path" cx="25" cy="25" r="20" fill="none" />
              </svg>
            </template>
            <template #footer v-if="showLoadCompanies">
              <el-button text bg size="small" @click="fetchCompanies">
                Load more companies
              </el-button>
            </template>
          </el-select-v2>
          <el-select
            id="selectedusercompany"
            v-model="selectedCompany"
            class="user-inputstyle"
            size="large"
            disabled
            v-if="showUserCompany"
          >
            <el-option
              v-for="(item, index) in companyData"
              :key="index"
              :label="item.companyName"
              :value="item.companyValue"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="First Name" class="firstLetterCapital">
          <el-input
            id="userfirstname"
            required
            v-model="inviteData.firstName"
            placeholder="First Name"
            :maxlength="maxNameLength"
            class="user-inputstyle"
          />
          <span class="fieldLength"
            >{{ maxNameLength - inviteData.firstName.length }} /
            {{ maxNameLength }}
          </span>
        </el-form-item>
        <el-form-item label="Last Name" class="firstLetterCapital">
          <el-input
            id="userlastname"
            required
            v-model="inviteData.lastName"
            placeholder="Last Name"
            :maxlength="maxNameLength"
            class="user-inputstyle"
          />
          <span class="fieldLength"
            >{{ maxNameLength - inviteData.lastName.length }} /
            {{ maxNameLength }}
          </span>
        </el-form-item>
        <el-form-item label="Email address" class="firstLetterCapital">
          <el-input
            id="useremail"
            required
            v-model="inviteData.email"
            placeholder="Enter Email"
            :maxlength="maxEmailLength"
            class="user-inputstyle"
          />
          <span class="fieldLength"
            >{{ maxEmailLength - inviteData.email.length }} /
            {{ maxEmailLength }}
          </span>
        </el-form-item>
      </el-form>
    </div>
    <div v-if="noemail" class="errordiv emailerror">
      Please enter the valid email address
    </div>
    <div v-if="hasError" class="errordiv">All the fields are required!</div>
    <div v-if="showError && addUserError !== ''" class="maxitemsdiv errordiv">
      <img src="@/assets/icons/warnerror.svg" class="warnimagestyle" />
      <label style="padding: 1rem 0"> {{ addUserError }}</label>
    </div>
    <div class="buttonsdiv">
      <el-button
        id="adduserbutton"
        round
        class="btnstyle ml-4"
        variant="warning"
        @click="AddUserInvite"
        :disabled="!enableControls || company === null"
        v-if="taskType === 'add' && !showUserCompany"
      >
        <i
          v-if="!enableControls && !showError"
          class="fa fa-refresh fa-spin"
        ></i>
        Send Invite
      </el-button>
      <el-button
        id="addcompanyuserbutton"
        round
        class="btnstyle ml-4"
        variant="warning"
        @click="AddUserInvite"
        :disabled="!enableControls || !showUserCompany"
        v-if="taskType === 'add' && showUserCompany"
      >
        <i
          v-if="!enableControls && !showError"
          class="fa fa-refresh fa-spin"
        ></i>
        Send Invite
      </el-button>
      <el-button
        id="usercancel"
        round
        variant="outline-dark"
        @click="cancelInvite"
        class="cancelbtn"
      >
        Cancel
      </el-button>
    </div>
  </div>
  <div v-if="taskType === 'emailSent'" class="modalbody">
    <div class="labeldiv">
      <img
        src="@/assets/icons/mailsent.svg"
        style="width: 70px; margin-bottom: 1rem"
      />
      <label class="labelbld">Notification email sent to: </label>
      <div
        v-for="(item, index) in notifiedUsers"
        :key="index"
        style="display: flex"
      >
        <p class="labelfont">
          <strong>{{ item.name }}</strong
          >&nbsp;<br />
          &#40;{{ item.email }} &#41;
        </p>
      </div>
    </div>
    <div class="buttonsdiv">
      <el-button
        id="adduserdone"
        round
        class="btnstyle"
        variant="warning"
        @click="cancelInvite"
        style="float: right"
      >
        Done
      </el-button>
    </div>
  </div>
</template>

<style lang="scss">
.errordiv {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #df444d;
}
.btn-link {
  color: #000000;
}
.imagestyle {
  width: 32px;
  height: 32px;
  margin-right: 20px;
  margin-top: 10px;
}

.cancelbtn {
  background: unset;
  border: 1px solid #333333;
  font-size: 0.8rem;
  line-height: 1rem;
  width: 140px !important;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}
.cancelbtn:hover {
  background: unset;
  color: #000000;
}
.fieldLength {
  font-style: italic;
  font-size: x-small;
  color: gray;
}

.path {
  animation: loading-dash 1.5s ease-in-out infinite;
  stroke-dasharray: 90, 150;
  stroke-dashoffset: 0;
  stroke-width: 5;
  stroke: #f2af00;
  stroke-linecap: round;
}
.circular {
  display: inline;
  height: 30px;
  width: 30px;
  animation: loading-rotate 2s linear infinite;
}
.el-select-dropdown__loading,
.el-select-dropdown__empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 20px;
}
.el-table__empty-block .el-table__empty-text {
  line-height: 35px !important;
}
@keyframes loading-rotate {
  to {
    transform: rotate(360deg);
  }
}
@keyframes loading-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -40px;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -120px;
  }
}
@keyframes custom-spin-move {
  to {
    opacity: 1;
  }
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import APIService from "@/utils/APIService";
import { NotifiedUser } from "@/utils/AppInterfaces";
import _ from "lodash";
import { FlowActionTypes } from "@/store/flowstore/FlowActionTypes";
import { UserActionTypes } from "@/store/userstore/UserActionTypes";
import { CompanyActionTypes } from "@/store/companystore/CompanyActionTypes";

export default defineComponent({
  name: "AddUserModal",
  components: {},
  computed: {
    ...mapGetters([
      "getAllCompanies",
      "getTaskType",
      "getCompanyData",
      "getCurrentUser",
    ]),
    compOptions(): any {
      if (this.getAllCompanies.length === 0) this.fetchCompanies();
      return this.getAllCompanies;
    },
    taskType(): string {
      return this.getTaskType;
    },
    userVal: {
      get(): string {
        return this.userval;
      },
      set(value: any) {
        this.userval = value.name;
        this.inviteData = value;
      },
    },
    companyInfo: {
      get(): string {
        return this.company;
      },
      set(value: any) {
        this.company = value;
      },
    },
    showUserCompany(): any {
      return !_.isEmpty(this.getCompanyData);
    },
    companyData(): any {
      this.setCompanyName();
      return [
        {
          companyName: this.getCompanyData.companyName,
          companyValue: this.getCompanyData.companyName,
        },
      ];
    },
  },
  data() {
    return {
      inviteData: {
        firstName: "",
        lastName: "",
        email: "",
        companyBucketId: "",
        userBucketId: null,
        role: null,
        roleId: 5,
      },
      userval: "",
      company: null as any,
      emaildata: {},
      maxNameLength: 128,
      maxEmailLength: 256,
      enableControls: true,
      heading: this.showheading,
      notifiedUsers: [] as NotifiedUser[],
      showError: false,
      loading: true,
      addUserError: "",
      noemail: false,
      hasError: false,
      selectedCompany: [],
      allCompaniesList: [] as any[],
      skipvalue: 1,
      showLoadCompanies: true,
    };
  },
  methods: {
    fetchCompanies() {
      const companyDataObj = {
        companyBucketIdList: null,
        searchPhrase: "",
        userCurrentRole: this.getCurrentUser.result.currentRole,
        pageOptions: {
          skip: this.skipvalue - 1,
          take: 10,
          sortField: null,
          sortOrder: null,
        },
      };
      const apiService = new APIService(this.axios);
      apiService.getCompanySearch(companyDataObj).then((res) => {
        this.loading = false;
        if (res.pagingData.totalPages >= this.skipvalue) {
          this.skipvalue = this.skipvalue + 1;
        } else this.showLoadCompanies = false;
        res.items.map((company: any) => {
          this.allCompaniesList.push({
            id: company.companyBucketId,
            label: company.companyName,
            value: company,
          });
        });
        this.$store.dispatch(
          CompanyActionTypes.SET_ALL_COMPANIES,
          this.allCompaniesList
        );
      });
    },
    setCompanyName() {
      if (!_.isEmpty(this.getCompanyData) && this.$route.name === "companies")
        this.company = this.getCompanyData;
      this.selectedCompany = this.getCompanyData.companyName;
    },
    emailValidation(email: string) {
      const pattern = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.+-]+\.[a-zA-Z]{2,4}$/;
      this.noemail = !pattern.test(email);
      return this.noemail;
    },
    AddUserInvite() {
      let inviteobj = null;
      let errormessage = "";
      const apiService = new APIService(this.axios);
      if (this.showUserCompany) {
        this.inviteData.companyBucketId = this.getCompanyData.companyBucketId;
      } else {
        this.inviteData.companyBucketId =
          this.company !== null ? this.company.companyBucketId : null;
      }
      inviteobj = this.inviteData;

      if (
        inviteobj.firstName === "" ||
        inviteobj.lastName === "" ||
        inviteobj.email === ""
      ) {
        this.hasError = true;
        this.noemail = false;
      } else {
        this.hasError = false;
        if (!this.emailValidation(inviteobj.email)) {
          this.enableControls = false;
          apiService.createUser(inviteobj).then((res) => {
            if (res.success) {
              const userDataObj = {
                userBucketIdList: null,
                companyBucketIdList: null,
                searchPhrase: null,
                companyName: null,
                pageOptions: {
                  skip: 0,
                  take: 10,
                  sortField: null,
                  sortOrder: null,
                },
              };
              this.showError = false;
              this.addUserError = "";
              apiService.getUsersList(userDataObj).then((resp) => {
                this.$store.dispatch(UserActionTypes.SET_USERS, resp);
              });
              this.notifiedUsers.push({
                name: `${this.inviteData.firstName} ${this.inviteData.lastName}`,
                email: this.inviteData.email,
              });
              this.$store.dispatch(FlowActionTypes.SET_TASK_TYPE, "emailSent");
            } else {
              errormessage = res.validationMessages.join(" ");
              this.showError = true;
              this.addUserError = errormessage;
            }
            this.enableControls = true;
          });
        }
      }
    },
    nextClickHandler() {
      this.$store.dispatch(FlowActionTypes.SET_TASK_TYPE, "summary");
      this.userval = "";
    },
    cancelInvite() {
      this.inviteData = {
        firstName: "",
        lastName: "",
        email: "",
        companyBucketId: "",
        userBucketId: null,
        role: null,
        roleId: 5,
      };
      if (this.$route.name === "user")
        this.$store.dispatch(FlowActionTypes.SET_TASK_TYPE, "add");
      else if (this.$route.name === "account" && this.taskType !== "add")
        this.$store.dispatch(FlowActionTypes.SET_TASK_TYPE, "account");
      else if (this.$route.name === "product")
        this.$store.dispatch(FlowActionTypes.SET_TASK_TYPE, "assign");
      this.userval = "";
      this.showError = false;
      this.company = null;
      this.addUserError = "";
      this.notifiedUsers = [];
      this.allCompaniesList = [];
      this.$store.dispatch(FlowActionTypes.SET_SHOW_ADDUSER, false);
      this.$store.dispatch(CompanyActionTypes.SET_ALL_COMPANIES, []);
    },
  },
  props: {
    showheading: Boolean,
  },
});
</script>
