import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SubscriptionSubDetails = _resolveComponent("SubscriptionSubDetails")!
  const _component_ProdSubOptions = _resolveComponent("ProdSubOptions")!
  const _component_SubscriptionAssignedUsers = _resolveComponent("SubscriptionAssignedUsers")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SubscriptionSubDetails),
    _createVNode(_component_ProdSubOptions),
    _createVNode(_component_SubscriptionAssignedUsers)
  ], 64 /* STABLE_FRAGMENT */))
}