export enum UserMutationTypes {
  UPDATE_USERS = "UPDATE_USERS",
  UPDATE_ASSIGNED_PRODUCTS = "UPDATE_ASSIGNED_PRODUCTS",
  UPDATE_CURRENTUSER = "UPDATE_CURRENTUSER",
  UPDATE_USER_ADDONS = "UPDATE_USER_ADDONS",
  UPDATE_USERS_WITHOUTCOMPANYADMIN = "UPDATE_USERS_WITHOUTCOMPANYADMIN",
  UPDATE_COMPANY_USERS = "UPDATE_COMPANY_USERS",
  UPDATE_UNASSIGNED_SUBSCRIPTIONUSERS = "UPDATE_UNASSIGNED_SUBSCRIPTIONUSERS",
  UPDATE_USER_ERROR = "UPDATE_USER_ERROR",
}
