import { LogLevel, PublicClientApplication } from "@azure/msal-browser";

const vcidServerUrl = process.env.VUE_APP_IDENTITY_SERVER_API as string;
const client = process.env.VUE_APP_CLIENTID as string;
const knownAuthority = new URL(vcidServerUrl).hostname as string;
export const msalConfig = {
  auth: {
    clientId: client,
    authority: vcidServerUrl,
    redirectUri: `${window.location.origin}/auth/login-callback.html`, // `${window.location.origin}/auth/login-callback.html`
    //postLogoutRedirectUri: window.location.origin,
    knownAuthorities: [knownAuthority],
  },
  cache: {
    cacheLocation: "sessionStorage",
    // temporaryCacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
    secureCookies: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean
      ) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          // case LogLevel.Info:
          //   console.info(message);
          //   return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
      logLevel: LogLevel.Verbose,
    },
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

const apiScopes = process.env.VUE_APP_EMS_API_SCOPES || "";

export const apiConfig = {
  b2cScopes: [apiScopes],
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: apiConfig.b2cScopes, //["openid", "offline_access"],
};

export const tokenRequest = {
  scopes: apiConfig.b2cScopes,
};
