<template>
  <el-table
    id="companysubscriptionstable"
    v-loading="loading"
    :data="subscriptionList"
    style="width: 100%"
    max-height="600px"
    scrollbar-always-on
    :header-cell-style="headerstyle"
    :header-row-style="headerrow"
    :row-style="rowdatastyle"
    empty-text="No subscriptions available"
  >
    <el-table-column label="Product Name" prop="productName">
      <template #default="props">
        <div class="celldiv">
          <img src="@/assets/icons/vcicon.svg" class="cellimage" />
          <div :class="props.row.expirationCount > 0 ? 'expirationlabel' : ''">
            {{ props.row.productName }}
          </div>
        </div>
      </template>
    </el-table-column>
    <el-table-column align="center" label="Version">
      <template #default="props">
        {{ props.row.majorVersionNumber }}.{{ props.row.minorVersionNumber }}
      </template>
    </el-table-column>
    <el-table-column align="center" label="Seats used">
      <template #default="props">
        {{ props.row.seatsUsed }}/{{ props.row.seats }}
      </template>
    </el-table-column>
    <!-- <el-table-column align="center" prop="expirationCount" sortable>
      <template v-slot:header>
        <label>Expiration &nbsp; </label>
        <label class="columnlabel"> (in 30 days)</label>
      </template>
      <template #default="props">
        <div :class="props.row.expirationCount !== 0 ? 'expirationlabel' : ''">
          {{ props.row.expirationCount }}
        </div>
      </template>
    </el-table-column> -->
    <el-table-column align="center" label="" width="120">
      <template #default="subcription">
        <el-button @click="viewDetails(subcription.row)" link size="small">
          <img src="@/assets/icons/openlistdetails.svg" style="width: 25px" />
        </el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-drawer
    v-model="showSubscriptionDetail"
    direction="rtl"
    size="40%"
    destroy-on-close
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <SubscriptionDetailModal />
  </el-drawer>
</template>

<style lang="scss">
.expirationlabel {
  color: #df444d;
  font-size: 14px;
  line-height: 1.53em;
  font-family: "Open Sans";
  font-weight: 400;
}
</style>

<script lang="ts">
import { defineComponent } from "vue";
import APIService from "@/utils/APIService";
import _ from "lodash";
import { mapGetters } from "vuex";
import SubscriptionDetailModal from "@/components/SubscriptionDetailModal.vue";
import { CompanyActionTypes } from "@/store/companystore/CompanyActionTypes";
import { ProductActionTypes } from "@/store/productstore/ProductActionTypes";
import { ProductInfomation } from "@/utils/AppInterfaces";
import { FlowActionTypes } from "@/store/flowstore/FlowActionTypes";

export default defineComponent({
  name: "CompanySubscriptions",
  components: {
    SubscriptionDetailModal,
  },
  computed: {
    ...mapGetters([
      "getCompanySubscriptions",
      "getShowSubscriptionDetails",
      "getCompanyData",
    ]),
    subscriptionList(): any {
      return this.getCompanySubscriptions.items;
    },
    showSubscriptionDetail: {
      get(): boolean {
        return this.getShowSubscriptionDetails;
      },
      set() {
        this.$store.dispatch(FlowActionTypes.SET_SUBSCRIPTION_DATA, {});
        this.$store.dispatch(ProductActionTypes.SET_ASSIGNED_USERS, []);
        this.$store.dispatch(
          ProductActionTypes.SET_SHOW_SUBSCRIPTIONDETAILS,
          false
        );
      },
    },
  },
  data() {
    return {
      newprodList: [],
      loading: true,
      headerstyle: {
        "border-bottom": "unset",
        background: "#F2F2F2",
        "font-family": "Open Sans, sans-serif",
        "font-weight": "700",
        "font-size": "0.8rem",
        "line-height": "1rem",
        color: "black",
      },
      headerrow: {
        background: "#F2F2F2",
      },
      rowdatastyle: {
        "font-family": "Open Sans, sans-serif",
        "font-weight": "400",
        "font-size": "0.8rem",
        "line-height": "1rem",
        color: "black",
      },
    };
  },
  methods: {
    getSubscriptions() {
      const apiService = new APIService(this.axios);
      const companyDataObj = {
        companyBucketIdCollection: [
          !_.isEmpty(this.getCompanyData)
            ? this.getCompanyData.companyBucketId
            : "",
        ],
      };
      apiService
        .getSubscriptionsListWithCompanyBucketId(companyDataObj)
        .then((res) => {
          this.$store.dispatch(
            CompanyActionTypes.SET_COMPANY_SUBSCRIPTIONS,
            res
          );
          this.loading = false;
        });
    },
    async viewDetails(sub: ProductInfomation) {
      this.$store.dispatch(
        ProductActionTypes.SET_SHOW_SUBSCRIPTIONDETAILS,
        true
      );
      await this.$store.dispatch(FlowActionTypes.SET_SUBSCRIPTION_DATA, sub);
    },
  },
  mounted() {
    this.getSubscriptions();
  },
});
</script>
