import {
  MutationTree,
  ActionTree,
  ActionContext,
  GetterTree,
  Store as VuexStore,
  CommitOptions,
  DispatchOptions,
  Module,
} from "vuex";
import { ProductMutationTypes } from "./ProductMutationTypes";
import { ProductActionTypes } from "./ProductActionTypes";
import { State, state } from "../AppState";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, State>, "commit">;

export interface Actions {
  [ProductActionTypes.SET_PRODUCTS](
    context: AugmentedActionContext,
    data: any
  ): Promise<boolean>;
  [ProductActionTypes.SET_SUBSCRIPTIONS](
    context: AugmentedActionContext,
    data: any
  ): Promise<boolean>;
  [ProductActionTypes.SET_ADDONS_LIST](
    context: AugmentedActionContext,
    data: any
  ): Promise<boolean>;
  [ProductActionTypes.SET_ASSIGNED_USERS](
    context: AugmentedActionContext,
    data: any
  ): Promise<boolean>;
  [ProductActionTypes.SET_AVAILABLE_PRODUCTS](
    context: AugmentedActionContext,
    show: boolean
  ): Promise<boolean>;
  [ProductActionTypes.SET_SHOW_SUBSCRIPTIONDETAILS](
    context: AugmentedActionContext,
    show: boolean
  ): Promise<boolean>;
}

export const actions: ActionTree<State, State> = {
  [ProductActionTypes.SET_PRODUCTS](
    context: AugmentedActionContext,
    data: any
  ) {
    return context.commit(ProductMutationTypes.UPDATE_PRODUCTS, data);
  },
  [ProductActionTypes.SET_SUBSCRIPTIONS](
    context: AugmentedActionContext,
    data: any
  ) {
    return context.commit(ProductMutationTypes.UPDATE_SUBSCRIPTIONS, data);
  },
  [ProductActionTypes.SET_ADDONS_LIST](
    context: AugmentedActionContext,
    data: any
  ) {
    return context.commit(ProductMutationTypes.UPDATE_ADDONS_LIST, data);
  },
  [ProductActionTypes.SET_ASSIGNED_USERS](
    context: AugmentedActionContext,
    show: boolean
  ) {
    return context.commit(ProductMutationTypes.UPDATE_ASSIGNED_USERS, show);
  },
  [ProductActionTypes.SET_AVAILABLE_PRODUCTS](
    context: AugmentedActionContext,
    data: any
  ) {
    return context.commit(ProductMutationTypes.UPDATE_AVAILABLE_PRODUCTS, data);
  },
  [ProductActionTypes.SET_SHOW_SUBSCRIPTIONDETAILS](
    context: AugmentedActionContext,
    show: boolean
  ) {
    return context.commit(
      ProductMutationTypes.UPDATE_SHOW_SUBSCRIPTIONDETAILS,
      show
    );
  },
};

export type Mutations<S = State> = {
  [ProductMutationTypes.UPDATE_PRODUCTS](state: S, data: any): void;
  [ProductMutationTypes.UPDATE_SUBSCRIPTIONS](state: S, data: any): void;
  [ProductMutationTypes.UPDATE_ADDONS_LIST](state: S, data: any): void;
  [ProductMutationTypes.UPDATE_ASSIGNED_USERS](state: S, show: boolean): void;
  [ProductMutationTypes.UPDATE_AVAILABLE_PRODUCTS](state: S, data: any): void;
  [ProductMutationTypes.UPDATE_SHOW_SUBSCRIPTIONDETAILS](
    state: S,
    show: boolean
  ): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [ProductMutationTypes.UPDATE_PRODUCTS](state, data: any) {
    state.productsList = data;
  },
  [ProductMutationTypes.UPDATE_SUBSCRIPTIONS](state, data: any) {
    state.subscriptionsList = data;
  },
  [ProductMutationTypes.UPDATE_ADDONS_LIST](state, data: any) {
    state.addonsList = data;
  },
  [ProductMutationTypes.UPDATE_ASSIGNED_USERS](state, data: any) {
    state.AssignedUserList = data;
  },
  [ProductMutationTypes.UPDATE_AVAILABLE_PRODUCTS](state, data: any) {
    state.availableProducts = data;
  },
  [ProductMutationTypes.UPDATE_SHOW_SUBSCRIPTIONDETAILS](state, show: boolean) {
    state.showSubscriptionDetails = show;
  },
};
export type Getters = {
  getProductsList(state: State): any;
  getSubscriptionsList(state: State): any;
  getAddonsList(state: State): any;
  getAssignedUserList(state: State): any;
  getAvailableProducts(state: State): any;
  getShowSubscriptionDetails(state: State): boolean;
};

export const getters: GetterTree<State, State> = {
  getProductsList: (state) => state.productsList,
  getSubscriptionsList: (state) => state.subscriptionsList,
  getAddonsList: (state) => state.addonsList,
  getAssignedUserList: (state) => state.AssignedUserList,
  getAvailableProducts: (state) => state.availableProducts,
  getShowSubscriptionDetails: (state) => state.showSubscriptionDetails,
};

export type ProductStore<S = State> = Omit<
  VuexStore<S>,
  "getters" | "commit" | "dispatch"
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>;
  };
};

export const productStore: Module<State, State> = {
  state,
  getters,
  mutations,
  actions,
};
