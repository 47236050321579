import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DetailsFlow = _resolveComponent("DetailsFlow")!
  const _component_UserSubDetails = _resolveComponent("UserSubDetails")!
  const _component_UsersOverview = _resolveComponent("UsersOverview")!
  const _component_UserDetails = _resolveComponent("UserDetails")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DetailsFlow, {
      overview: "Users",
      detail: "User"
    }),
    (!_ctx.showDetails)
      ? (_openBlock(), _createBlock(_component_UserSubDetails, { key: 0 }))
      : _createCommentVNode("v-if", true),
    (_ctx.showDetails)
      ? (_openBlock(), _createBlock(_component_UsersOverview, { key: 1 }))
      : _createCommentVNode("v-if", true),
    (!_ctx.showDetails)
      ? (_openBlock(), _createBlock(_component_UserDetails, { key: 2 }))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}