<template>
  <SubscriptionSubDetails />
  <ProdSubOptions />
  <SubscriptionAssignedUsers />
</template>

<style lang="scss">
.detcontent {
  box-sizing: border-box;
  flex-direction: column;
  padding: 2% 4vw;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import SubscriptionSubDetails from "./SubscriptionSubDetails.vue";
import ProdSubOptions from "./ProdSubOptions.vue";
import SubscriptionAssignedUsers from "./SubscriptionAssignedUsers.vue";

export default defineComponent({
  name: "SubscriptionDetails",
  components: {
    SubscriptionSubDetails,
    ProdSubOptions,
    SubscriptionAssignedUsers,
  },
  data() {
    return {};
  },
});
</script>
