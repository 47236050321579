import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "common-layout" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_el_affix = _resolveComponent("el-affix")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_affix, {
          offset: 0,
          position: "top"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_header, null, {
              default: _withCtx(() => [
                (_ctx.userAuthenticated)
                  ? (_openBlock(), _createBlock(_component_HeaderComponent, { key: 0 }))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }),
        (_ctx.envName !== '')
          ? (_openBlock(), _createBlock(_component_el_row, {
              key: 0,
              class: "envClass"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Environment : " + _toDisplayString(_ctx.envName), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        _createVNode(_component_el_main, null, {
          default: _withCtx(() => [
            (_ctx.userError)
              ? (_openBlock(), _createBlock(_component_ErrorMessage, { key: 0 }))
              : _createCommentVNode("v-if", true),
            (_ctx.userAuthenticated && !_ctx.userError)
              ? (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    })
  ]))
}