import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "font-bold-32"
}
const _hoisted_2 = {
  key: 1,
  class: "font-bold-32"
}
const _hoisted_3 = { class: "font-regular-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item")!
  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createBlock(_component_el_row, { class: "rowstyle" }, {
    default: _withCtx(() => [
      (!_ctx.showprod)
        ? (_openBlock(), _createElementBlock("h1", _hoisted_1, _toDisplayString(_ctx.overviewName), 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
      (_ctx.showprod)
        ? (_openBlock(), _createElementBlock("h1", _hoisted_2, "Products & Services"))
        : _createCommentVNode("v-if", true),
      _createVNode(_component_el_breadcrumb, { style: {"background":"unset","padding":"0.5em 0 0 0","margin":"unset"} }, {
        default: _withCtx(() => [
          _createVNode(_component_el_breadcrumb_item, {
            active: "",
            onClick: _ctx.viewOverview
          }, {
            default: _withCtx(() => [
              (!_ctx.showprod)
                ? (_openBlock(), _createElementBlock("h5", {
                    key: 0,
                    class: _normalizeClass(_ctx.showDetails ? 'flowstyle' : 'font-regular-20')
                  }, _toDisplayString(_ctx.overviewName) + " Overview ", 3 /* TEXT, CLASS */))
                : _createCommentVNode("v-if", true),
              (_ctx.showprod)
                ? (_openBlock(), _createElementBlock("h5", {
                    key: 1,
                    class: _normalizeClass(_ctx.showDetails ? 'flowstyle' : 'font-regular-20')
                  }, _toDisplayString(_ctx.overviewName) + " Overview ", 3 /* TEXT, CLASS */))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["onClick"]),
          (_ctx.showDetails)
            ? (_openBlock(), _createBlock(_component_el_breadcrumb_item, {
                key: 0,
                active: "",
                to: "#"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("h5", _hoisted_3, _toDisplayString(_ctx.detailName) + " detail", 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}