import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { typeof: "primary" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardCards = _resolveComponent("DashboardCards")!
  const _component_SubscriptionsList = _resolveComponent("SubscriptionsList")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.currentUserEmpty)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_DashboardCards, {
            showHeader: "",
            onDashboardCardsLoaded: _ctx.updateDashboardCardsStatus
          }, null, 8 /* PROPS */, ["onDashboardCardsLoaded"]),
          _createVNode(_component_SubscriptionsList, { onSubsListLoaded: _ctx.updatesubscriptionsListStatus }, null, 8 /* PROPS */, ["onSubsListLoaded"])
        ])), [
          [_directive_loading, !_ctx.contentReady]
        ])
      : _createCommentVNode("v-if", true)
  ])), [
    [
      _directive_loading,
      _ctx.currentUserEmpty,
      void 0,
      {
        fullscreen: true,
        lock: true
      }
    ]
  ])
}