import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/icons/borrowed.svg'
import _imports_1 from '@/assets/icons/assigned.svg'
import _imports_2 from '@/assets/icons/pagefirstenabled.svg'
import _imports_3 from '@/assets/icons/pagefirstdisabled.svg'
import _imports_4 from '@/assets/icons/pagelastenabled.svg'
import _imports_5 from '@/assets/icons/pagelastdisabled.svg'


const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "borrowcell" }
const _hoisted_3 = {
  key: 0,
  src: _imports_0,
  style: {"width":"25px"}
}
const _hoisted_4 = {
  key: 1,
  src: _imports_1,
  style: {"width":"25px"}
}
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { key: 3 }
const _hoisted_7 = {
  key: 1,
  class: "pagingdiv"
}
const _hoisted_8 = { class: "pagelabel" }
const _hoisted_9 = {
  key: 0,
  src: _imports_2,
  style: {"width":"20px"}
}
const _hoisted_10 = {
  key: 1,
  src: _imports_3,
  style: {"width":"20px"}
}
const _hoisted_11 = {
  key: 0,
  src: _imports_4,
  style: {"width":"20px"}
}
const _hoisted_12 = {
  key: 1,
  src: _imports_5,
  style: {"width":"20px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showSubscription)
      ? _withDirectives((_openBlock(), _createBlock(_component_el_table, {
          key: 0,
          ref: "assignedUserstbl",
          id: "assignedusertable",
          data: _ctx.usersList,
          "max-height": "500",
          "scrollbar-always-on": "",
          "header-cell-style": _ctx.headerstyle,
          "row-style": _ctx.rowdatastyle,
          onSelectionChange: _ctx.handleSelectionChange,
          "row-class-name": _ctx.rowDisableWithStatus,
          "cell-class-name": _ctx.disableCellWithCheckedout,
          "empty-text": "No users assigned",
          class: "topmargin"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, {
              type: "selection",
              width: "50"
            }),
            _createVNode(_component_el_table_column, { label: "User Name" }, {
              default: _withCtx((scope) => [
                (scope.row.status === 'Checked-out')
                  ? (_openBlock(), _createBlock(_component_el_tooltip, {
                      key: 0,
                      placement: "top"
                    }, {
                      content: _withCtx(() => [
                        _createTextVNode("The license is checked out, so cannot remove assignment ")
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("div", null, _toDisplayString(scope.row.userName), 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */))
                  : _createCommentVNode("v-if", true),
                (scope.row.status !== 'Checked-out')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(scope.row.userName), 1 /* TEXT */))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_table_column, {
              label: "Email",
              prop: "email"
            }),
            _createVNode(_component_el_table_column, {
              align: "center",
              label: "Subscription End Date",
              prop: "expirationDate"
            }),
            _createVNode(_component_el_table_column, {
              label: "Add-on",
              align: "center",
              prop: "addOns"
            }, {
              default: _withCtx((props) => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.row.addOns, (addon, index) => {
                  return (_openBlock(), _createElementBlock("div", { key: index }, _toDisplayString(addon.productName), 1 /* TEXT */))
                }), 128 /* KEYED_FRAGMENT */))
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_table_column, {
              align: "center",
              label: "Status",
              prop: "status"
            }, {
              default: _withCtx((user) => [
                _createElementVNode("div", _hoisted_2, [
                  (user.row.status === 'Checked-out')
                    ? (_openBlock(), _createElementBlock("img", _hoisted_3))
                    : _createCommentVNode("v-if", true),
                  (user.row.status === 'Assigned')
                    ? (_openBlock(), _createElementBlock("img", _hoisted_4))
                    : _createCommentVNode("v-if", true),
                  (user.row.status === 'Checked-out')
                    ? (_openBlock(), _createElementBlock("label", _hoisted_5, " Borrowed"))
                    : _createCommentVNode("v-if", true),
                  (user.row.status === 'Assigned')
                    ? (_openBlock(), _createElementBlock("label", _hoisted_6, _toDisplayString(user.row.status), 1 /* TEXT */))
                    : _createCommentVNode("v-if", true)
                ])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["data", "header-cell-style", "row-style", "onSelectionChange", "row-class-name", "cell-class-name"])), [
          [_directive_loading, _ctx.loading]
        ])
      : _createCommentVNode("v-if", true),
    (_ctx.totalPages > 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("label", _hoisted_8, [
            _createElementVNode("strong", null, "Page " + _toDisplayString(_ctx.currentPage) + " / " + _toDisplayString(_ctx.totalPages), 1 /* TEXT */)
          ]),
          _createVNode(_component_el_button, {
            plain: "",
            class: "paginationbtn",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.currentPage = 1)),
            id: "fiestpage",
            disabled: _ctx.currentPage === 1
          }, {
            default: _withCtx(() => [
              (_ctx.currentPage !== 1)
                ? (_openBlock(), _createElementBlock("img", _hoisted_9))
                : _createCommentVNode("v-if", true),
              (_ctx.currentPage === 1)
                ? (_openBlock(), _createElementBlock("img", _hoisted_10))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["disabled"]),
          _createVNode(_component_el_pagination, {
            layout: "prev, next",
            total: _ctx.totalRowsCount,
            "page-size": 10,
            "current-page": _ctx.currentPage,
            "onUpdate:currentPage": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentPage) = $event))
          }, null, 8 /* PROPS */, ["total", "current-page"]),
          _createVNode(_component_el_button, {
            plain: "",
            class: "paginationbtn",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.currentPage = _ctx.totalPages)),
            id: "lastpage",
            disabled: _ctx.currentPage === _ctx.totalPages
          }, {
            default: _withCtx(() => [
              (_ctx.currentPage !== _ctx.totalPages)
                ? (_openBlock(), _createElementBlock("img", _hoisted_11))
                : _createCommentVNode("v-if", true),
              (_ctx.currentPage === _ctx.totalPages)
                ? (_openBlock(), _createElementBlock("img", _hoisted_12))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["disabled"])
        ]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}