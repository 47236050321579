import {
  MutationTree,
  ActionTree,
  ActionContext,
  GetterTree,
  Store as VuexStore,
  CommitOptions,
  DispatchOptions,
  Module,
} from "vuex";
import { UserMutationTypes } from "./UserMutationTypes";
import { UserActionTypes } from "./UserActionTypes";
import { State, state } from "../AppState";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, State>, "commit">;

export interface Actions {
  [UserActionTypes.SET_USERS](
    context: AugmentedActionContext,
    data: any
  ): Promise<boolean>;
  [UserActionTypes.SET_USER_ERROR](
    context: AugmentedActionContext,
    data: any
  ): Promise<boolean>;
  [UserActionTypes.SET_USERS_WITHOUTCOMPANYADMIN](
    context: AugmentedActionContext,
    data: any
  ): Promise<boolean>;
  [UserActionTypes.SET_ASSIGNED_PRODUCTS](
    context: AugmentedActionContext,
    show: boolean
  ): Promise<boolean>;
  [UserActionTypes.SET_CURRENTUSER](
    context: AugmentedActionContext,
    data: any
  ): Promise<boolean>;
  [UserActionTypes.SET_USER_ADDONS](
    context: AugmentedActionContext,
    data: any
  ): Promise<boolean>;
  [UserActionTypes.SET_COMPANY_USERS](
    context: AugmentedActionContext,
    show: boolean
  ): Promise<boolean>;
  [UserActionTypes.SET_UNASSIGNED_SUBSCRIPTIONUSERS](
    context: AugmentedActionContext,
    show: boolean
  ): Promise<boolean>;
}

export const actions: ActionTree<State, State> = {
  [UserActionTypes.SET_USERS](context: AugmentedActionContext, data: any) {
    return context.commit(UserMutationTypes.UPDATE_USERS, data);
  },
  [UserActionTypes.SET_USER_ERROR](context: AugmentedActionContext, data: any) {
    return context.commit(UserMutationTypes.UPDATE_USER_ERROR, data);
  },
  [UserActionTypes.SET_USERS_WITHOUTCOMPANYADMIN](
    context: AugmentedActionContext,
    show: boolean
  ) {
    return context.commit(
      UserMutationTypes.UPDATE_USERS_WITHOUTCOMPANYADMIN,
      show
    );
  },
  [UserActionTypes.SET_ASSIGNED_PRODUCTS](
    context: AugmentedActionContext,
    data: any
  ) {
    return context.commit(UserMutationTypes.UPDATE_ASSIGNED_PRODUCTS, data);
  },
  [UserActionTypes.SET_CURRENTUSER](
    context: AugmentedActionContext,
    data: any
  ) {
    return context.commit(UserMutationTypes.UPDATE_CURRENTUSER, data);
  },
  [UserActionTypes.SET_USER_ADDONS](
    context: AugmentedActionContext,
    data: any
  ) {
    return context.commit(UserMutationTypes.UPDATE_USER_ADDONS, data);
  },
  [UserActionTypes.SET_COMPANY_USERS](
    context: AugmentedActionContext,
    data: any
  ) {
    return context.commit(UserMutationTypes.UPDATE_COMPANY_USERS, data);
  },
  [UserActionTypes.SET_UNASSIGNED_SUBSCRIPTIONUSERS](
    context: AugmentedActionContext,
    show: boolean
  ) {
    return context.commit(
      UserMutationTypes.UPDATE_UNASSIGNED_SUBSCRIPTIONUSERS,
      show
    );
  },
};

export type Mutations<S = State> = {
  [UserMutationTypes.UPDATE_USERS](state: S, data: any): void;
  [UserMutationTypes.UPDATE_USER_ERROR](state: S, data: any): void;
  [UserMutationTypes.UPDATE_USERS_WITHOUTCOMPANYADMIN](
    state: S,
    data: any
  ): void;
  [UserMutationTypes.UPDATE_ASSIGNED_PRODUCTS](state: S, data: any): void;
  [UserMutationTypes.UPDATE_CURRENTUSER](state: S, data: any): void;
  [UserMutationTypes.UPDATE_USER_ADDONS](state: S, data: any): void;
  [UserMutationTypes.UPDATE_COMPANY_USERS](state: S, data: any): void;
  [UserMutationTypes.UPDATE_UNASSIGNED_SUBSCRIPTIONUSERS](
    state: S,
    data: any
  ): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [UserMutationTypes.UPDATE_USERS](state, data: any) {
    state.usersList = data;
  },
  [UserMutationTypes.UPDATE_USER_ERROR](state, data: any) {
    state.userError = data;
  },
  [UserMutationTypes.UPDATE_USERS_WITHOUTCOMPANYADMIN](state, data: any) {
    state.usersWithoutAdmins = data;
  },
  [UserMutationTypes.UPDATE_ASSIGNED_PRODUCTS](state, data: any) {
    state.assProdList = data;
  },
  [UserMutationTypes.UPDATE_CURRENTUSER](state, data: any) {
    state.currentUser = data;
  },
  [UserMutationTypes.UPDATE_USER_ADDONS](state, data: any) {
    state.userAddons = data;
  },
  [UserMutationTypes.UPDATE_COMPANY_USERS](state, data: any) {
    state.companyUsers = data;
  },
  [UserMutationTypes.UPDATE_UNASSIGNED_SUBSCRIPTIONUSERS](state, data: any) {
    state.unassignedSubscriptionUsers = data;
  },
};
export type Getters = {
  getUsersList(state: State): any;
  getAssProdList(state: State): any;
  getCurrentUser(state: State): any;
  getUserAddons(state: State): any;
  getUsersWithoutAdmins(state: State): any;
  getCompanyUsers(state: State): any;
  getUnassignedSubscriptionUsers(state: State): any;
  getUserError(state: State): any;
};

export const getters: GetterTree<State, State> = {
  getUsersList: (state) => state.usersList,
  getAssProdList: (state) => state.assProdList,
  getCurrentUser: (state) => state.currentUser,
  getUserAddons: (state) => state.userAddons,
  getUsersWithoutAdmins: (state) => state.usersWithoutAdmins,
  getCompanyUsers: (state) => state.companyUsers,
  getUnassignedSubscriptionUsers: (state) => state.unassignedSubscriptionUsers,
  getUserError: (state) => state.userError,
};

export type UserStore<S = State> = Omit<
  VuexStore<S>,
  "getters" | "commit" | "dispatch"
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>;
  };
};

export const userstore: Module<State, State> = {
  state,
  getters,
  mutations,
  actions,
};
