/* eslint-disable @typescript-eslint/no-unused-vars */
import { createApp } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "@/assets/AppStyles.scss"; //importing application styles
// import SSOAuth from "./utils/SSOAuth";
import { AuthenticationResult, EventType } from "@azure/msal-browser";
import { msalPlugin } from "./plugins/msalPlugin";
import { msalInstance } from "./authConfig";
import { CustomNavigationClient } from "./router/NavigationClient";
import { loginRequest } from "@/authConfig";

const navigationClient = new CustomNavigationClient(router);
msalInstance.setNavigationClient(navigationClient);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});
await msalInstance.initialize();
await msalInstance
  .handleRedirectPromise()
  .then((authResult) => {
    const account = msalInstance.getActiveAccount();
    if (!account) {
      // redirect anonymous user to login page
      msalInstance.loginRedirect(loginRequest);
    }
  })
  .catch((err) => {
    console.error(err.message);
    if (err) msalInstance.loginRedirect(loginRequest);
  });

const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app.use(ElementPlus).use(VueAxios, axios);

// Use API server in production builds
if (typeof process.env.VUE_APP_EMS_SERVER_API !== "undefined") {
  if (process.env.VUE_APP_EMS_SERVER_API !== "") {
    app.axios.defaults.baseURL = process.env.VUE_APP_EMS_SERVER_API;
  }
}

app.use(store).use(router);
app.use(msalPlugin, msalInstance);
router.isReady().then(() => {
  // Waiting for the router to be ready prevents race conditions when returning from a loginRedirect or acquireTokenRedirect
  app.mount("#app");
});

/*
SSOAuth.startup().then((ok: boolean) => {
  if (ok) {
    app.use(store).use(router).mount("#app");
  }
});
*/
